import {
  listResource,
  createResource,
  deleteResource,
} from "../clients/resource"

export async function listSessionRecords(id) {
  if (id) {
    return listResource(`sessions/${id}/facilitators`)
  }

  return listResource("facilitators")
}

export async function createRecord(groupSessionId, userId) {
  return createResource(`session_facilitators`, { sessionFacilitator: { userId, groupSessionId } })
}

export async function destroyRecord(id) {
  return deleteResource("session_facilitators", id)
}

// Return a list of users who are not already in the group
//
// facilitators - records data from redux store
export function findUsersFromFacilitators(facilitators = []) {
  return facilitators.map((member) => member.attributes.userId)
}

export function findSessionIdFromFacilitator(facilitator) {
  return facilitator.attributes.groupSessionId
}