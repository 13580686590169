import { all, takeEvery, put, select } from "redux-saga/effects"
import { listSessionRecords, destroyRecord, createRecord } from "services/facilitator"
import { findGroupId } from "services/session"
import { addFacilitator, removeFacilitator } from "redux/sessions/actions"
import { getFacilitators, getSessions } from "../sagas/selectors"
import { FETCH_SINGLE as FETCH_SESSION } from "../sessions/sagas"
import { FETCH_MEMBERS } from "../groups/sagas"
import {
  shouldFetchRecords,
  findRecord,
  findRecords,
  makeCall,
} from "../sagas/utils"
import actions, {
  fetchFacilitatorsSuccess,
  fetchFacilitatorsFail,
  createFacilitatorSuccess,
  createFacilitatorFail,
  destroyFacilitatorSuccess,
  destroyFacilitatorFail,
} from "./actions"

function* getState() {
  return yield select(getFacilitators)
}

function* getSessionsState() {
  return yield select(getSessions)
}

export function* FETCH_ALL({ payload: { sessionId } }) {
  const facilitatorState = yield* getState()

  if (shouldFetchRecords(facilitatorState, sessionId)) {
    yield* makeCall(listSessionRecords, fetchFacilitatorsSuccess, fetchFacilitatorsFail, sessionId)
    return
  }

  yield put(fetchFacilitatorsSuccess(findRecords(facilitatorState, sessionId)))
}

export function* FETCH_SESSION_FACILITATOR_INFO({ payload: { sessionId } }) {
  yield* FETCH_SESSION({ payload: { id: sessionId } })
  const sessionsState = yield* getSessionsState()
  const session = findRecord(sessionsState, sessionId)

  if (!session) { return }

  yield* FETCH_ALL({ payload: { sessionId, id: sessionId } })

  const groupId = findGroupId(session)
  yield* FETCH_MEMBERS({ payload: { id: groupId  } })
}

export function* CREATE({ payload }) {
  const { sessionId, userId } = payload
  const success = yield* makeCall(
    createRecord,
    createFacilitatorSuccess,
    createFacilitatorFail,
    sessionId, userId
  )
  if (success) {
    yield put(addFacilitator({ id:  sessionId, item: success.data }))
  }
}

export function* DESTROY({ payload: { id, sessionId } }) {
  const success = yield* makeCall(
    destroyRecord,
    destroyFacilitatorSuccess,
    destroyFacilitatorFail,
    id
  )

  if (success) {
    yield put(removeFacilitator({ id:  sessionId, item: { id } }))
  }
}

export default function* facilitatorsSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DESTROY, DESTROY),
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.FETCH_FACILITATOR_INFO, FETCH_SESSION_FACILITATOR_INFO),
  ])
}
