import moment from "moment"

const actions = {
  CREATE: "seasons/CREATE",
  UPDATE: "seasons/UPDATE",

  DELETE: "seasons/DELETE",
  DELETE_OK: "seasons/DELETE_OK",
  DELETE_FAIL: "seasons/DELETE_FAIL",

  FETCH_SINGLE: "seasons/FETCH_SINGLE",
  FETCH_SINGLE_OK: "seasons/FETCH_SINGLE_OK",
  FETCH_SINGLE_FAIL: "seasons/FETCH_SINGLE_FAIL",

  FETCH_ALL: "seasons/FETCH_ALL",
  FETCH_ALL_OK: "seasons/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "seasons/FETCH_ALL_FAIL",

  FETCH_MEMBERS: "seasons/FETCH_MEMBERS",
  FETCH_MEMBERS_SUCCESS: "seasons/FETCH_MEMBERS_SUCCESS",
  FETCH_MEMBERS_FAIL: "seasons/FETCH_MEMBERS_FAIL",

  SET_STATE: "seasons/SET_STATE",
}

export const fetchSeason = id => ({
  type: actions.FETCH_SINGLE,
  payload: { id },
})

export const fetchSeasonSuccess = record => ({
  type: actions.FETCH_SINGLE_OK,
  payload: { record, error: null },
})

export const fetchSeasonFail = error => ({
  type: actions.FETCH_SINGLE_FAIL,
  payload: { error },
})

export const createSeason = payload => ({
  type: actions.CREATE,
  payload,
})

export const updateSeason = payload => ({
  type: actions.UPDATE,
  payload
})

export const closeSeason = id => ({
  type: actions.UPDATE,
  payload: { id, closedOn: moment().format("YYYY-MM-DD") }
})

export const reopenSeason = id => ({
  type: actions.UPDATE,
  payload: { id, closedOn: null }
})

export const deleteSeason =  id => ({
  type: actions.DELETE,
  payload: { id },
})

export const deleteSeasonSuccess = () => ({
  type: actions.DELETE_OK,
  payload: {},
})

export const deleteSeasonFail = error => ({
  type: actions.DELETE_FAIL,
  payload: { error },
})

export const fetchSeasons = (organizationId, params = {}) => ({
  type: actions.FETCH_ALL,
  payload: { organizationId, id: organizationId, params },
})

export const fetchSeasonsSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchSeasonsFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const fetchMembers = id => ({
  type: actions.FETCH_MEMBERS,
  payload: { id },
})

export const fetchMembersSuccess = payload => ({
  type: actions.FETCH_MEMBERS_SUCCESS,
  payload,
})

export const fetchMembersFail = payload => ({
  type: actions.FETCH_MEMBERS_FAIL,
  payload,
})

export default actions
