import { put, call } from "redux-saga/effects"
/*
  Private functions - not for export

  recordIsStale   -> returns Boolean if cached record is stale (and should be refetched)
  recordsAreStale -> returns Boolean if cached records are stale (and should be refetched)
  recordIsLoaded  -> returns Boolean if record is stored in 'record'
  fetchingRecord  -> returns Boolean if we are already fetching record
  fetchingRecords -> returns Boolean if we are already fetching records
*/

// Checking state of some selector group
function recordIsStale(state, findId) {
  // Consider a record that is loaded in the 'record' position as valid and always 'fresh'
  // Because it is so often updated
  if (recordIsLoaded(state, findId)) { return false }

  // If the record is found in the records collection instead, check if _they_ are stale
  return recordsAreStale(state)
}


function recordsAreStale(state) {
  const { recordsFetchedAt }= state
  if (!recordsFetchedAt) { return true }

  const cacheFor = 3600000 // one hour in milliseconds
  return new Date() - recordsFetchedAt > cacheFor // invalid if more than an hour old
}

function recordIsLoaded(state, findId) {
  const { record } = state
  return record && record.id === findId
}

/*
  Boilerplate saga helpers
  The logic assumes the state is the default state of reducer/utils.js

  shouldFetchRecord   -> returns Boolean to indicate if record should be refetched
  shouldFetchRecords  -> returns Boolean to indicate if records should be refetched
  shouldFetchResource -> returns Boolean to indicate if you should fetch resource for all records
  findRecord          -> returns found record (or null if not found)
  findRecords         -> returns found records (or null if not found)
  requestFailed       -> tells you whether request failed (by errors array or status)
  displayErrors       -> knows how to parse JSON API spec "errors" array or provides default message if server errors with no msg
  makeCall            -> handles simple case of a call which has a success and failure callback
*/

export function shouldFetchRecord(state, findId) {
  const found = findRecord(state, findId)
  const invalid = recordIsStale(state, findId)

  if (!found) { return true }

  return invalid;
}


export function shouldFetchRecords(state, collectionId) {
  const found = findRecords(state, collectionId)
  const invalid = recordsAreStale(state)

  if (!found) { return true }

  return invalid;
}

export function shouldFetchResource(state, findId, resource) {
  const found = findRecord(state, findId)
  const foundResource = found && found[`${resource}FetchedAt`]
  const invalid = recordIsStale(state, findId)

  if (!found || !foundResource) { return true }

  return invalid;
}

export function findRecord(state, id) {
  const { records, record } = state
  if (record && record.id === id) { return record }

  return records.find(rec => rec.id  === id)
}

export function findRecords(state, collectionId) {
  const { records, collectionFor } = state
  if (collectionFor === collectionId) { return records }

  return null
}

export function requestFailed(response) {
  const { errors = [], status } = response

  return errors.length > 0 || status > 399
}

export function displayErrors(response) {
  const { errors = [], status } = response
  const defaultMessage = `The request failed with status ${status}`

  if (errors.length === 0 ) { return [defaultMessage] }

  return errors
}

export function* makeCall(initialCall, successCallback, failureCallback, ...args) {
  const response = yield call(initialCall, ...args)

  if (requestFailed(response)) {
    yield put(failureCallback(displayErrors(response)))
    return false
  }

  yield put(successCallback(response.data))
  return response
}
