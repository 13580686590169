import { all, takeEvery, put, call } from "redux-saga/effects"
import { notification } from "antd"
import { destroy, update } from "services/memberships"
import { updateOrganizationData } from "../organizations/sagas"
import actions, {
  destroyMembershipSuccess,
  destroyMembershipFail,
} from "./actions"

export function* CHANGE_ROLE({ payload: { id, role }}) {
  const { data, errors = [] } = yield call(update, id, role)

  // does this need a changeRoleFail dispatch? i'm not sure
  // TODO decide if it needs a changeRoleFail dispatch
  if (errors.length > 0) {
    notification.warning({
      message: "Request Failed",
      description: errors,
    })
  } else {
    yield put({
      type: actions.CHANGE_ROLE_SUCCESS,
      payload: data
    })
  }
}

export function* DESTROY({ payload: { id } }) {
  const { data, included, errors = [] } = yield call(destroy, id)

  if (errors.length > 0) {
    notification.warning({
      message: "Request Failed",
      description: errors,
    })
    yield put(destroyMembershipFail())
    return
  }

  notification.success({
    message: "Member Removed",
    description: "The selected member was removed from this organization.",
  })
  yield call(updateOrganizationData, data, included)
  yield put(destroyMembershipSuccess())
}

export default function* membershipsSaga() {
  yield all([
    takeEvery(actions.CHANGE_ROLE, CHANGE_ROLE),
    takeEvery(actions.DESTROY, DESTROY),
  ])
}
