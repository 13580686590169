import { all, takeEvery, put, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import { listRecords, getRecord, createRecord, updateRecord, deleteRecord } from "services/organization"
import { pathFor } from "services/util"
import { notification } from 'antd'
import actions, {
  createOrganizationSuccess,
  createOrganizationFail,
  fetchOrganizationSuccess,
  fetchOrganizationFail,
  fetchOrganizationsSuccess,
  fetchOrganizationsFail,
  updateOrganizationSuccess,
  updateOrganizationFail,
} from "./actions"
import {
  makeCall,
} from "../sagas/utils"
import { setMemberships } from "../memberships/actions"
import { setInvitations } from "../invitations/actions"
import { setUsers } from "../users/actions"

export function* FETCH_ALL() {
  const { data, errors = [] } = yield call(listRecords)

  if (errors.length > 0) {
    yield put(fetchOrganizationsFail(errors))
    return
  }

  yield put(fetchOrganizationsSuccess(data))
}

export function* FETCH_SINGLE({ payload: { id } }) {
  const { data, included, errors = [] } = yield call(getRecord, id)

  if (errors.length > 0) {
    yield put(fetchOrganizationFail(errors))
    return
  }

  yield call(updateOrganizationData, data, included)
}

// the order of these actions matters. putting the fetchOrganizationSuccess
// last because it is what will set the loading state back to false.
// we want the rest of the data in place first.
export function* updateOrganizationData(data, included = []) {
  yield put(setMemberships(
    included.filter(x => x.type === "membership")))
  yield put(setInvitations(
    included.filter(x => x.type === "invitation")))
  yield put(setUsers(
    included.filter(x => x.type === "user")))
  yield put(fetchOrganizationSuccess(data))
}

export function* CREATE({ payload }) {
  const { name, description } = payload

  const success = yield* makeCall(
    createRecord,
    createOrganizationSuccess,
    createOrganizationFail,
    name, description
  )

  if (success) {
    const { data: { id } } = success
    yield put(push(pathFor("organizationsShow", {id})))
  }
}

export function* DELETE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: true,
    },
  })

  const { data } = yield call(deleteRecord, payload)

  if(data)
  {
    notification.success({
      message: "Deleted Organization",
      description: "The organization has been deleted.",
    })
    yield put(push(pathFor("adminOrganizationsIndex")))
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: false,
    },
  })
}

export function* UPDATE({ payload }) {
  const { id, name, description } = payload

  const success = yield* makeCall(
    updateRecord,
    updateOrganizationSuccess,
    updateOrganizationFail,
    id, name, description
  )

  if (success) {
    yield put(push(pathFor("organizationsShow", {id})))
  }
}

export default function* organizationsSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE),
  ])
}
