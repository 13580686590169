import React from "react"
import { Layout } from "antd"
import { Link, withRouter } from "react-router-dom"
import styles from "./style.module.scss"

@withRouter
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: false,
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  render() {
    const { children } = this.props
    const { backgroundNumber, backgroundEnabled } = this.state
    let backgroundImage
    if (backgroundEnabled) {
      backgroundImage = `url('resources/images/photos/${backgroundNumber}.jpeg')`
    } else {
      backgroundImage = "none"
    }


    return (
      <Layout>
        <Layout.Content>
          <div
            className={backgroundEnabled ? `${styles.layout} ${styles.light}` : `${styles.layout}`}
            style={{ backgroundImage }}
          >
            <div className={styles.header}>
              <div className={styles.logo}>
                <Link to="/">PLC Tracker</Link>
              </div>
              <nav className={styles.navigation}>
                <ul className={styles.navigationItems}>
                  <li>
                    <Link to="/user/login" className={styles.navigationActive}>
                      Login
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`}>
              <p>&copy; 2020 Unabridged Software. All rights reserved.</p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
