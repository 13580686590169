import axios from "axios"
import convertKeys from "../helpers/convertKeys"
import { sessionKey } from "../services/user"

async function authenticatedRequest(path, method, params = {}) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.defaults.headers.common["Content-Type"] = "application/json"
  axios.defaults.headers.common.Authorization = `Bearer ${sessionKey()}`

  const modifiedParams = convertKeys(params, "snakeCase")

  try {
    const { data, status } = await axios.request({
      method,
      url: path,
      params: method === "GET" ? modifiedParams : {},
      data: modifiedParams,
    })

    return Object.assign({ status }, convertKeys(data, "camelCase"))
  } catch (error) {
    const errors = error.response?.data?.errors || error.message
    return { data: {}, errors }
  }
}

export default authenticatedRequest
