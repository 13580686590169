const actions = {
  SET_STATE: "currentUser/SET_STATE",

  FORGOT: "currentUser/FORGOT",

  LOGIN: "currentUser/LOGIN",
  LOAD_CURRENT_ACCOUNT: "currentUser/LOAD_CURRENT_ACCOUNT",
  LOGOUT: "currentUser/LOGOUT",

  RESET: "currentUser/RESET",

  UPDATE: "currentUser/UPDATE",
  UPDATE_EMAIL: "currentUser/UPDATE_EMAIL",
  UPDATE_PASSWORD: "currentUser/UPDATE_PASSWORD",
}

export const setState = attrs => ({
  type: actions.SET_STATE,
  payload: attrs,
})

export const startSaving = () => setState({ saving: true })
export const stopSaving = () => setState({ saving: false })
export const startLoading = () => setState({ loading: true })
export const stopLoading = () => setState({ loading: false })

export const clearCurrentAccount = () => ({
  type: actions.LOGOUT,
  payload: {},
})

export const logout = () => ({
  type: actions.LOGOUT,
  payload: { performLogout: true },
})

export default actions
