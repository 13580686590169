import actions from "./actions"

const initialState = {
  records: [],
  record: {},
  id: null,

  loading: false,
  saving: false,
  error: null,
}

export default function membershipsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DESTROY:
      return {
        ...state,
        id: action.payload.id,
        saving: action.payload.id,
      }

    case actions.DESTROY_OK:
      return {
        ...state,
        saving: false,
      }

    case actions.DESTROY_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload.error,
      }

    case actions.CREATE:
      return {
        ...state,
        record: action.payload.record,
        saving: true,
      }

    case actions.CREATE_OK:
      return {
        ...state,
        saving: false,
      }

    case actions.CREATE_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload.error,
      }

    case actions.CHANGE_ROLE:
       return {
        ...state,
        record: action.payload.record,
        saving: action.payload.saving,
      }

    case actions.CHANGE_ROLE_SUCCESS:
      return {
        ...state,
        saving: false,
        records: state.records.map(record => {
          if (record.id === action.payload.id) {
            return action.payload
          }
          return record
        })
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
