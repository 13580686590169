export const toCamelCase = string =>
  string
    .replace(/([-_][a-z])/gi, $1 =>
      $1
        .toUpperCase()
        .replace('-', '')
        .replace('_', ''),
    )
    .replace(/([-_][0-9])/gi, $1 => $1.replace('-', '').replace('_', ''))

export const humanizeCamelCase = string =>
  string
    .split(/(?=[A-Z])/)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
    .split(/(?=[0-9])/)
    .join(' ')

export const toSnakeCase = string =>
  string
    .split(/(?=[A-Z])/)
    .join('_')
    .split(/(?=[0-9])/)
    .join('_')
    .toLowerCase()

export const cleanParams = params => {
  const clean = {}
  Object.keys(params).forEach(key => { if (params[key] !== undefined ) { clean[key] = params[key] } })
  return clean;
}

const convertKeys = (obj, conversionType) => {
  const newO = {}
  const convert = conversionType === 'snakeCase' ? toSnakeCase : toCamelCase
  if (obj instanceof Array) {
    return obj.map(value =>
      typeof value === 'object' ? convertKeys(value, conversionType) : value,
    )
  }

  const cleanObj = cleanParams(obj);

  Object.keys(cleanObj).forEach(origKey => {
    const hasOwnProp = Object.prototype.hasOwnProperty.call(obj, origKey)
    if (hasOwnProp) {
      const newKey = convert(origKey)
      let value = obj[origKey]
      if (value instanceof Array || (value !== null && value.constructor === Object)) {
        value = convertKeys(value, conversionType)
      }
      newO[newKey] = value
    }
  })
  return newO
}

export default convertKeys
