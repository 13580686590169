import actions from "./actions"
import {
  deleteReducer,
  deleteReducerComplete,
} from "../reducers/utils"

const initialState = {
  records: [],
  record: null,
  id: null,

  loading: false,
  saving: false,
  error: null,
}

export default function seasonsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DELETE:
      return deleteReducer(state, action)

    case actions.DELETE_OK:
    case actions.DELETE_FAIL:
      return deleteReducerComplete(state, action)

    case actions.FETCH_SINGLE:
      return {
        ...state,
        loading: true,
      }

    case actions.FETCH_SINGLE_OK:
      return {
        ...state,
        loading: false,
        record: action.payload.record,
        id: action.payload.record.id,
        error: null,
      }

    case actions.FETCH_SINGLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case actions.FETCH_ALL:
      return {
        ...state,
        loading: true,
      }

    case actions.FETCH_ALL_OK:
      return {
        ...state,
        loading: false,
        records: action.payload.records,
        error: null,
      }

    case actions.FETCH_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case actions.UPDATE:
      return { ...state, saving: true }

    case actions.FETCH_MEMBERS:
      if (state.id === action.payload.id) {
        state.record.members = []
        state.record.membersLoading = true
      }
      return {
        ...state,
        record: state.record,
        records: state.records.map(record => {
          if (record.id === action.payload.id) {
            record.members = []
            record.membersLoading = true
            return record
          }
          return record
        })
      }

    case actions.FETCH_MEMBERS_SUCCESS:
      if (state.id === action.payload.id) {
        state.record.members = action.payload.members
        state.record.membersLoading = false
      }
      return {
        ...state,
        record: state.record,
        records: state.records.map(record => {
          if (record.id === action.payload.id) {
            record.members = action.payload.members
            record.membersLoading = false
            return record
          }
          return record
        })
      }

    case actions.FETCH_MEMBERS_FAIL:
      if (state.id === action.payload.id) {
        state.record.membersLoadingError = action.payload.error
        state.record.membersLoading = false
      }
      return {
        ...state,
        record: state.record,
        records: state.records.map(record => {
          if (record.id === action.payload.id) {
            record.membersLoadingError = action.payload.error
            record.membersLoading = false
            return record
          }
          return record
        })
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
