import {
  getResource,
  listResource,
  updateResource,
  createResource,
  deleteResource,
} from "../clients/resource"
import { routes } from "../router"

export async function listRecords(id) {
  if (id) {
    return listResource(`seasons/${id}/groups`)
  }

  return listResource("groups")
}

export async function createRecord(seasonId, name, goal, guidingQuestion) {
  const params = { name, goal, guidingQuestion }
  return createResource(`seasons/${seasonId}/groups`, { group: params })
}

export async function deleteRecord(seasonId, id) {
  return deleteResource(`seasons/${seasonId}/groups`, id)
}

export async function getRecord(seasonId, id) {
  return getResource(`seasons/${seasonId}/groups`, id)
}

export async function updateRecord(id, seasonId, name, goal, guidingQuestion) {
  const params = { name, goal, guidingQuestion }
  return updateResource(`seasons/${seasonId}/groups`, id, { group: params })
}

export async function listMemberRecords(id) {
  if (id) {
    return listResource(`groups/${id}/members`)
  }

  return listResource("members")
}

export async function listSessionRecords(id) {
  if (id) {
    return listResource(`groups/${id}/sessions`)
  }

  return listResource("sessions")
}

export function findOrganizationId(group) {
  if (!group) {
    return null;
  }

  return group.relationships.organization.data.id;
}

export function findSeasonId(group) {
  if (!group) {
    return null;
  }

  if (group.seasonId) {
    return group.seasonId
  }
  // return null

  return group.relationships.season.data.id;
}

// Return a list of users who are not already in the group
//
// orgUsers - user data from redux store
// groupMembers - members data from redux store
export function findUnassignedUsers(users = [], groupMembers = []) {
  const groupMemberIds = groupMembers.map((member) => member.attributes.userId)
  const guests = users.filter((user) => !groupMemberIds.includes(user.id))

  return guests;
}

export function groupRoute(routeName, group) {
  const route = routes.find(r => r.name === routeName)

  if (!route) { return null }

  const { path } = route
  const pathSegments = path.split("/")
  const routeSegments = pathSegments.map(segment => {
    if (segment.startsWith(":")) {
      switch (segment) {
        case ":id":
          return group.id || findSeasonId(group)
        case ":seasonId":
          return findSeasonId(group)
        case ":organizationId":
          return findOrganizationId(group)
        default:
          return group.id
      }
    }

    return segment
  })

  return `${routeSegments.join("/")}`
}
