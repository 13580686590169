import {
  createResource,
  deleteResource,
  getResource,
  listResource,
  updateResource,
} from "../clients/resource"

export async function getRecord(id) {
  return getResource("seasons", id)
}

export async function createRecord(id, name, startOn) {
  return createResource(`organizations/${id}/seasons`, { season: { name, startOn } })
}

export async function deleteRecord(id) {
  return deleteResource("seasons", id)
}

export async function listRecords(id = null, params = {}) {
  if (id) {
    return listResource(`organizations/${id}/seasons`, params)
  }

  return listResource("seasons", params)
}

export async function updateRecord(id, params) {
  return updateResource("seasons", id, { season: params })
}

export async function listMemberRecords(id) {
  if (id) {
    return listResource(`seasons/${id}/members`)
  }

  return listResource("members")
}

export function getOrganizationId(season) {
  if (!season) {
    return null
  }

  return season.relationships.organization.data.id
}
