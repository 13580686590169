/*
  Helpers - not for export
*/

// Array Helpers

export const addItemToArray = (item, array) => {
  return array.concat([item]).filter(Boolean)
}

export const updateItemInArray = (item, array) => {
  if (!item) { return array }

  return array.map(record => {
    if (record.id === item.id) {
      return item
    }
    return record
  })
}

export const removeItemInArray = (item, array) => {
  if (!item) { return array }

  return array.filter(record => record.id !== item.id )
}

const addResourceToItemInArray = ({ id, records, resource }) => {
  return records.map(record => {
    if (record.id === id) {
      return addResource({ record, resource })
    }
    return record
  })
}

const setResourceToItemInArray = ({ id, records, resourceRecords, error, resource }) => {
  return records.map(record => {
    if (record.id === id) {
      return setResource({ record, resourceRecords, error, resource })
    }
    return record
  })
}

export const addResourceItem = (id, records, resource, item) => {
  return records.map(record => {
    if (record.id === id) {
      return addItem(record, resource, item)
    }
    return record
  })
}

export const removeResourceItem = (id, records, resource, item) => {
  return records.map(record => {
    if (record.id === id) {
      return removeResource(record, resource, item)
    }
    return record
  })
}

// Change record (set or get)
const addResourceIfRecord = ({ record, id, resource }) => {
  if (!record || !id) { return record }

  if (record.id === id) {
    return addResource({ record, resource })
  }

  return record
}

export const setResourceIfRecord = ({ record, id, resource, resourceRecords, error }) => {
  if (!record || !id) { return record }

  if (record.id === id) {
    return setResource({ record, resource, resourceRecords, error })
  }

  return record
}

const addResource = ({ record, resource }) => {
  const original = record || {}

  return Object.assign({
      [resource]: [],
      [`${resource}FetchedAt`]: null,
    },
    original, {
      [`${resource}Loading`]: true,
      [`${resource}LoadingError`]: null,
  })
}

const setResource = ({ record, resourceRecords, error, resource }) => {
  const original = record || {}
  const fetchedAt = error ? null : new Date()

  return Object.assign(
    {},
    original,
    {
      [resource]: resourceRecords,
      [`${resource}Loading`]: false,
      [`${resource}LoadingError`]: error,
      [`${resource}FetchedAt`]: fetchedAt,
    }
  )
}

const getResource = (record, resource) => {
  if (!record)  { return null }
  return record[resource]
}

const addItem = (record, resource, item) => {
  const origResourceRecords = getResource(record, resource)
  if (!origResourceRecords) { return record }

  const updatedRecords = addItemToArray(item, origResourceRecords)
  return setResource({ record, resourceRecords: updatedRecords, resource })
}

const removeResource = (record, resource, item) => {
  const origResourceRecords = getResource(record, resource)
  const remainingRecords = removeItemInArray(item, origResourceRecords)
  return setResource({ record, resourceRecords: remainingRecords, resource })
}

export const lockRequestState = (field) => {
  return {
    [field]: {
      lock: false,
      data: {},
      loading: true,
      error: null,
    }
  }
}

export const lockRequestCompleteState = ({ field, data, error, userId }) => {
  const { lockedById } = (data && data.attributes) || {}
  const lockAcquired = lockedById === userId
  return {
    [field]: {
      lock: lockAcquired,
      data,
      loading: false,
      error,
    }
  }
}

export const getLockAttrs = (field, record) => {
  return record && record.locks && record.locks[field] || {}
}

/*
 Default initial state
*/


export const initialState = {
  id: null,
  record: null,
  records: [],
  recordIds: [],
  recordsFetchedAt: null, // Timestamp of records last fetched
  collectionFor: null,   // ID of resource that collection is related to, or "index" if all user has access to

  loading: false,
  loadingError: null, // for Fetch actions, which might require redirects

  error: null,       // for Save/Create actions, which require display
  saving: false,     // false, true (for create) or the ID of the record that is being saved
  invalid: false,
}


/*
  Boilerplate reducers
  The logic might be more complicated than this, or might update related or additional state
  Any additional logic can call these helpers

  These support CRUD and REST API resource actions

  Create
  Update
  Delete
  Get    (show)
  GetAll (index)
  GetResource (record/:id/resource)
  add/remove Resource Items


  Each resource also has a 'Complete' state that sets errors and data (if they have them)
  These boilerplate reducers manage state in the application
*/

export const createReducer = (state) => {
  return {
    ...state,
    saving: true,
    error: null,
    id: null,
    record: null,
  }
}

export const createReducerComplete = (state, action) => {
  const { payload: { record, error } } = action
  const newRecords = addItemToArray(record, state.records)
  return {
    ...state,
    saving: false,
    error,
    id: record && record.id,
    record,
    records: newRecords,
    recordIds: newRecords.map(rec => rec.id),
  }
}

export const autoSaveReducer = (state, action) => {
  const { payload: { id } } = action
  return {
    ...state,
    autoSaving: id,
    saving: false,
    error: null,
  }
}

export const updateReducer = (state, action) => {
  const { payload: { id } } = action
  return {
    ...state,
    autoSaving: false,
    saving: id,
    error: null,
  }
}

export const updateReducerComplete = (state, action) => {
  const { payload: { record, error } } = action
  const newRecord = record || state.record
  const newRecords = updateItemInArray(newRecord, state.records)
  return {
    ...state,
    saving: false,
    error,
    record: newRecord,
    records: newRecords,
  }
}


export const deleteReducer = (state, action) => {
  const { payload: { id } } = action
  return {
    ...state,
    saving: id,
    error: null,
    id,
  }
}

export const deleteReducerComplete = (state, action) => {
  const { payload: { error } } = action
  const { records, record, id } = state
  const filteredRecords = error ? records : removeItemInArray({ id }, records)
  const removedRecord = error ? record : null
  return {
    ...state,
    saving: false,
    error,
    id: null,
    record: removedRecord,
    records: filteredRecords,
  }
}

export const getReducer = (state, action) => {
  const { payload: { id } } = action
  return {
    ...state,
    error: null, // remove errors for single record
    loading: true,
    loadingError: null,
    id,
  }
}

export const getReducerComplete = (state, action) => {
  const { payload: { record, error } } = action
  return {
    ...state,
    loading: false,
    loadingError: error,
    record,
  }
}

export const getAllReducer = (state, action) => {
  const { payload: { id } } = action
  return {
    ...state,
    collectingFor: id || "index",
    loading: true,
    loadingError: null,
  }
}

export const getAllReducerComplete = (state, action) => {
  const { payload: { records, error } } = action
  const newRecords = records || []
  const fetchedAt = records ? new Date() : null
  return {
    ...state,
    loading: false,
    loadingError: error,
    collectionFor: state.collectingFor,
    records: newRecords,
    recordIds: newRecords.map(rec => rec.id),
    recordsFetchedAt: fetchedAt,
  }
}

export const getResourceReducer = (state, action, resource) => {
  const { payload: { id } } = action
  const { record, records } = state
  const newRecord = addResourceIfRecord({ record, id, resource })
  const newRecords = addResourceToItemInArray({ id, records, resource })
  return {
    ...state,
    record: newRecord,
    records: newRecords,
  }
}

export const getResourceReducerComplete = (state, action, resource) => {
  const { payload } = action
  const { id, error } = payload
  const  { record, records } = state
  const resourceRecords = payload[resource]

  const newRecord = setResourceIfRecord({ id, record, resource, resourceRecords, error })
  const newRecords = setResourceToItemInArray({ id, records, resource, resourceRecords, error })
  return {
    ...state,
    record: newRecord,
    records: newRecords,
  }
}

export const removeResourceItemReducer = (state, action, resource) => {
  const { payload: { id, item } } = action
  const { records } = state

  const newRecords = removeResourceItem(id, records, resource, item)
  return {
    ...state,
    records: newRecords,
  }
}

export const addResourceItemReducer = (state, action, resource) => {
  const { payload: { id, item } } = action
  const { records } = state

  const newRecords = addResourceItem(id, records, resource, item)
  return {
    ...state,
    records: newRecords,
  }
}
