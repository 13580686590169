import actions from "./actions"
import {
  deleteReducer,
  deleteReducerComplete,
  getReducer,
  getReducerComplete,
  getAllReducer,
  getAllReducerComplete,
  createReducer,
  createReducerComplete,
  autoSaveReducer,
  updateReducer,
  updateReducerComplete,
  getResourceReducer,
  getResourceReducerComplete,
  addResourceItemReducer,
  removeResourceItemReducer,
  addItemToArray,
  updateItemInArray,
  removeItemInArray,
  initialState,
  lockRequestState,
  lockRequestCompleteState,
} from "../reducers/utils"
import { findRecord } from "../sagas/utils"


const updateComplete = (state, action) => {
  const { payload: { record = {} } } = action
  // The only change from updateReducerComplete is this line
  // We merge the old record with the new because we fetch locks on this record that are
  // not returned with every refresh.  So we want to preserve those between updates
  const newRecord = Object.assign({}, state.record, record)
  const newState = Object.assign({}, state, { record: newRecord })
  return updateReducerComplete(newState, action)
}

const attachmentCreate = (state) => {
  return {
    ...state,
    saving: true,
    error: null,
  }
}

const attachmentComplete = (state, action) => {
  const { payload: { sessionId, attachment, attachmentType, error } } = action
  const record = findRecord(state, sessionId)
  const attachmentTypes = record.attributes[attachmentType]
  const newAttachmentTypes = addItemToArray(attachment, attachmentTypes)
  record.attributes[attachmentType] = newAttachmentTypes
  const newRecords = updateItemInArray(record, state.records)
  const newRecord = state.record.id === sessionId ? record : state.record
  return {
    ...state,
    saving: false,
    error,
    id: newRecord?.id,
    record: newRecord,
    records: newRecords,
    recordIds: newRecords.map(rec => rec.id),
  }
}

const attachmentDestroy = (state, action) => {
  const { payload: { id } } = action

  return {
    ...state,
    saving: id,
    error: null,
  }
}

const destroyAttachmentComplete = (state, action) => {
  const { payload: { sessionId, id, attachmentType, error } } = action
  const record = findRecord(state, sessionId)
  const attachmentTypes = record.attributes[attachmentType]
  const newAttachmentTypes = error ? attachmentTypes : removeItemInArray({ id }, attachmentTypes)
  record.attributes[attachmentType] = newAttachmentTypes
  const newRecords = updateItemInArray(record, state.records)
  const newRecord = state.record.id === sessionId ? record : state.record
  return {
    ...state,
    saving: false,
    error,
    id: newRecord?.id,
    record: newRecord,
    records: newRecords,
    recordIds: newRecords.map(rec => rec.id),
  }
}

const lockReducer = (state, action) => {
  const { id, field } = action.payload
  const { record } = state
  const currentLocks = (record && record.locks) || {}
  const newLockState = lockRequestState(field)
  const locks = Object.assign({}, currentLocks, newLockState)
  const mergedRecord = Object.assign({}, record, { locks })
  const newRecord = record.id === id ? mergedRecord : record
  return {
    ...state,
    record: newRecord,
  }
}

const lockReducerComplete = (state, action) => {
  const { id, field, data, error, userId } = action.payload
  const { record } = state
  const lockCompleteState = lockRequestCompleteState({field, data, error, userId})
  const currentLocks = (record && record.locks) || {}
  const locks = Object.assign({}, currentLocks, lockCompleteState)
  const mergedRecord = Object.assign({}, record, { locks })
  const newRecord = record.id === id ? mergedRecord : record

  return {
    ...state,
    record: newRecord,
  }
}



/*
 * This function gets called from the entitiesReducer in `../reducers.js`
 * and delegates actions to functions within this file based on the action type
 * @param  {Object} state   the entities Redux state slice
 * @param  {Object} action  the called action
 */
export default function sessionsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DESTROY:
      return deleteReducer(state, action)

    case actions.DESTROY_OK:
    case actions.DESTROY_FAIL:
      return deleteReducerComplete(state, action)

    case actions.FETCH_SINGLE:
      return getReducer(state, action);

    case actions.FETCH_SINGLE_SUCCESS:
    case actions.FETCH_SINGLE_FAIL:
      return getReducerComplete(state, action);

    case actions.FETCH_ALL:
      return getAllReducer(state, action)

    case actions.FETCH_ALL_OK:
    case actions.FETCH_ALL_FAIL:
      return getAllReducerComplete(state, action)

    case actions.CREATE:
      return createReducer(state, action)

    case actions.CREATE_SUCCESS:
    case actions.CREATE_FAIL:
      return createReducerComplete(state, action)

    case actions.UPDATE_AUTO_SAVE:
      return autoSaveReducer(state, action)
    case actions.UPDATE:
      return updateReducer(state, action)

    case actions.UPDATE_SUCCESS:
    case actions.UPDATE_FAIL:
      return updateComplete(state, action)

    case actions.FETCH_FACILITATORS:
      return getResourceReducer(state, action, "facilitators")

    case actions.FETCH_FACILITATORS_SUCCESS:
    case actions.FETCH_FACILITATORS_FAIL:
      return getResourceReducerComplete(state, action, "facilitators")

    case actions.ADD_FACILITATOR:
      return addResourceItemReducer(state, action, "facilitators")
    case actions.REMOVE_FACILITATOR:
      return removeResourceItemReducer(state,  action,  "facilitators")

    case actions.CREATE_ATTACHMENT:
      return attachmentCreate(state, action)
    case actions.CREATE_ATTACHMENT_SUCCESS:
    case actions.CREATE_ATTACHMENT_FAIL:
      return attachmentComplete(state, action)

    case actions.DESTROY_ATTACHMENT:
      return attachmentDestroy(state, action)
    case actions.DESTROY_ATTACHMENT_SUCCESS:
    case actions.DESTROY_ATTACHMENT_FAIL:
      return destroyAttachmentComplete(state, action)

    case actions.GET_LOCK:
    case actions.LOCK:
    case actions.UNLOCK:
      return lockReducer(state, action)

    case actions.LOCK_SUCCESS:
    case actions.LOCK_FAIL:
    case actions.GET_LOCK_SUCCESS:
    case actions.GET_LOCK_FAIL:
    case actions.UNLOCK_SUCCESS:
    case actions.UNLOCK_FAIL:
      return lockReducerComplete(state, action)

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
