import { all, put, call, select, takeEvery } from "redux-saga/effects"
import { getLeftMenuData, getTopMenuData } from "services/menu"
import { isAdmin } from "services/user"
import actions from "./actions"

export function* GET_DATA() {
  const user = yield select(({ currentUser }) => currentUser)

  const menuLeftData = yield call(getLeftMenuData, isAdmin(user))
  const menuTopData = yield call(getTopMenuData)

  yield put({
    type: actions.SET_STATE,
    payload: {
      menuLeftData,
      menuTopData,
    },
  })
}

export default function* menuSaga() {
  yield all([
    takeEvery(actions.UPDATE, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
