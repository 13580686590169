import axios from "axios"
import convertKeys from "../helpers/convertKeys"

/**
 * Generic Client - this sets up a promise, but it's up to the caller to
 * establish the `then` and `catch` clauses on their own. All this does is make
 * a request
 */

async function unauthenticatedRequest(path, method, params) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.defaults.headers.common["Content-Type"] = "application/json"

  const modifiedParams = convertKeys(params, "snake_case")

  try {
    const { data, status } = await axios.request({
      method,
      url: path,
      data: modifiedParams,
    })
    return Object.assign({ status }, convertKeys(data, "camelCase"))
  } catch (error) {
    const errors = error.response?.data?.errors || error.message
    return { data: {}, errors }
  }
}

export default unauthenticatedRequest
