import authenticatedRequest from "./authenticatedRequest"
import unauthenticatedRequest from "./unauthenticatedRequest"

// Create action for a resource.
export async function createResource(path, params = {}) {
  return authenticatedRequest(path, "POST", params)
}

// Delete the specified resource.
export async function deleteResource(path, id, params = {}) {
  return authenticatedRequest(`/${path}/${id}`, "DELETE", params)
}

// Get show action for specified resource.
export async function getResource(path, id, params = {}) {
  return authenticatedRequest(`/${path}/${id}`, "GET", params)
}

// Get show action for specified resource.
export async function unauthenticatedGetResource(path, id, params = {}) {
  return unauthenticatedRequest(`/${path}/${id}`, "GET", params)
}

// Get index action for specified resource.
export async function listResource(path, params = {}) {
  return authenticatedRequest(`/${path}`, "GET", params)
}

// Send an update request to /:path/:id via api
export async function updateResource(path, id, params = {}) {
  return authenticatedRequest(`/${path}/${id}`, "PUT", params)
}

export async function updateUnauthenticated(path, params = {}) {
  return unauthenticatedRequest(path, "PUT", params)
}

export async function updateAuthenticated(path, params = {}) {
  return authenticatedRequest(path, "PUT", params)
}
