import { all, takeEvery, put, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import { notification } from "antd"
import Honeybadger from "honeybadger-js"
import { LoggedOutUser } from "redux/currentUser/reducers"
import {
  currentAccount,
  login,
  loggedIn,
  logout,
  resetPassword,
  resetPasswordGenerate,
  update,
  updateUserEmail,
  updateUserPassword,
} from "services/user"
import actions, {
  setState,
  startSaving,
  stopSaving,
  startLoading,
  stopLoading,
  clearCurrentAccount,
} from "./actions"

export function* FORGOT({ payload }) {
  const { email } = payload
  const { errors = [] } = yield call(resetPasswordGenerate, { email })

  if (errors.length > 0) {
    notification.warning({
      message: "Reset Email not sent",
      description: errors,
    })
    return
  } 
  notification.success({
    message: "Reset Email sent",
    description: "An email to reset your password has been sent to your email address.",
  })
  yield put(push(`/login`))
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put(startLoading())

  const { data, included, errors = [] } = yield call(login, email, password)

  if (errors.length > 0) {
    notification.warning({
      message: "Login Failed",
      description: errors,
    })
    yield put(stopLoading())
    yield put(clearCurrentAccount())
    return
  }

  const { attributes, relationships } = data
  const { sessionKey } = attributes
  const orgIds = relationships.organizations.data.map(o => o.id)

  Honeybadger.setContext({
    currentUserId: data.id,
  });

  // We store our session token in localStorage,
  localStorage.setItem("sessionKey", sessionKey)

  notification.success({
    message: "Logged In",
    description: "You have successfully logged in!",
  })

  // Find session user id from relationships field
  const userId = relationships.user.data.id
  // Find included record with matching user id
  const user = included.find(i => i.id === userId)
  const { username: name, admin, currentOrganizationId, currentOrganizationRole } = user.attributes
  const organizations = included.filter(i => i.type === "organization" && orgIds.includes(i.id))
  const invitations = included.filter(i => i.type === "invitation")
  const invitationOrganizationIds = invitations.map(i => i.relationships.organization.data.id)

  const invitationOrganizations = included.filter(org =>
    org.type === "organization" && invitationOrganizationIds.includes(org.id)
  )

  yield put(setState({
    authorized: true,
    avatar: "",
    currentOrganizationId,
    email: user.attributes.email,
    id: userId,
    invitationOrganizations,
    invitations,
    name,
    organizations,
    role: admin ? "admin" : currentOrganizationRole,
  }))

  yield put({ type: "menu/UPDATE" })

  yield put(stopLoading())
}

export function* LOAD_CURRENT_ACCOUNT() {
  if (!loggedIn()) {
    return
  }

  yield put(startLoading())

  const { data, errors = [], status, included } = yield call(currentAccount)

  if (errors.length > 0) {
    notification.warning({
      message: `Fetch Failed: status ${status}`,
      description: errors,
    })
    yield put(clearCurrentAccount())
  } else if (data) {
    const { id, attributes, relationships } = data
    const orgIds = relationships.organizations.data.map(o => o.id)
    Honeybadger.setContext({
      currentUserId: id,
    });
    const { email, username: name, admin, currentOrganizationId, currentOrganizationRole } = attributes
    const organizations = included.filter(i => i.type === "organization" && orgIds.includes(i.id))
    const invitations = included.filter(i => i.type === "invitation")
    const invitationOrganizationIds = invitations.map(i => i.relationships.organization.data.id)

    const invitationOrganizations = included.filter(org =>
      org.type === "organization" && invitationOrganizationIds.includes(org.id)
    )

    yield put(setState({
      authorized: true,
      avatar: "",
      currentOrganizationId,
      email,
      id,
      invitationOrganizations,
      invitations,
      name,
      organizations,
      role: admin ? "admin" : currentOrganizationRole,
    }))
  }

  yield put({ type: "menu/UPDATE" })
  yield put(stopLoading())
}

export function* LOGOUT({ payload: { performLogout } }) {
  if (performLogout) {
    yield call(logout)
    Honeybadger.setContext({
      currentUserId: null,
    });
  }

  yield put(setState(LoggedOutUser))
}

export function* RESET({ payload }) {
  const { id, password, passwordConfirmation } = payload
  const { errors = [] } = yield call(resetPassword, id, password, passwordConfirmation)

  if (errors.length > 0) {
    notification.warning({
      message: "Password Reset failed",
      description: errors,
    })
    return
  } 
  notification.success({
    message: "Successfully Reset password",
    description: "Your password has been reset.",
  })
  yield put(push(`/login`))
}

export function* UPDATE({ payload }) {
  yield put(startSaving())

  const { data, errors = [] } = yield call(update, payload)

  if (errors.length > 0) {
    notification.warning({
      message: "Update Failed",
      description: errors,
    })
    yield put(stopSaving())
    return
  }

  if (data) {
    notification.success({
      message: "Updated profile",
      description: "Your profile has been updated!",
    })

    yield put(setState({name: payload.username}))
  }

  yield put(stopSaving())
}

export function* UPDATE_EMAIL({ payload }) {
  const { newEmail, password } = payload

  yield put(startSaving())

  const { data, errors = [] } = yield call(updateUserEmail, newEmail, password)

  if (errors.length > 0) {
    notification.warning({
      message: "Update Failed",
      description: errors,
    })
    return
  }

  if (data) {
    notification.success({
      message: "Updated email",
      description: "Your profile has been updated!",
    })

    yield put(setState({ email: newEmail }))
  }

  yield put(stopSaving())
}

export function* UPDATE_PASSWORD({ payload }) {
  const { password, newPassword } = payload

  yield put(startSaving())

  const { data, errors = [] } = yield call(updateUserPassword, password, newPassword)

  if (errors.length > 0) {
    notification.warning({
      message: "Update Failed",
      description: errors,
    })
    return
  }

  if (data) {
    notification.success({
      message: "Updated password",
      description: "Your profile has been updated!",
    })
  }

  yield put(stopSaving())
}

export default function* currentUserSaga() {
  yield all([
    takeEvery(actions.FORGOT, FORGOT),
    takeEvery(actions.RESET, RESET),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_EMAIL, UPDATE_EMAIL),
    takeEvery(actions.UPDATE_PASSWORD, UPDATE_PASSWORD),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
