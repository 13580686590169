const actions = {
  SET_STATE: "facilitators/SET_STATE",

  FETCH_ALL: "facilitators/FETCH_ALL",
  FETCH_ALL_OK: "facilitators/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "facilitators/FETCH_ALL_FAIL",
  FETCH_FACILITATOR_INFO: "facilitators/FETCH_FACILITATOR_INFO",

  CREATE: "facilitators/CREATE",
  CREATE_SUCCESS: "facilitators/CREATE_SUCCESS",
  CREATE_FAIL: "facilitators/CREATE_FAIL",

  DESTROY: "facilitators/DESTROY",
  DESTROY_SUCCESS: "facilitators/DESTROY_SUCCESS",
  DESTROY_FAIL: "facilitators/DESTROY_FAIL",
}

export const setState = attrs => ({
  type: actions.SET_STATE,
  payload: attrs,
})

export const fetchFacilitators = sessionId => ({
  type: actions.FETCH_ALL,
  payload: { sessionId, id: sessionId },
})

export const fetchFacilitatorInfo = sessionId => ({
  type: actions.FETCH_FACILITATOR_INFO,
  payload: { sessionId, id: sessionId },
})


export const fetchFacilitatorsSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchFacilitatorsFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const createFacilitator = payload => ({
  type: actions.CREATE,
  payload,
})

export const createFacilitatorSuccess = record => ({
  type: actions.CREATE_SUCCESS,
  payload: { record },
})

export const createFacilitatorFail = error => ({
  type: actions.CREATE_FAIL,
  payload: { error },
})

export const destroyFacilitator = payload => ({
  type: actions.DESTROY,
  payload,
})

export const destroyFacilitatorSuccess = payload => ({
  type: actions.DESTROY_SUCCESS,
  payload: { payload }
})

export const destroyFacilitatorFail = error => ({
  type: actions.DESTROY_FAIL,
  payload: { error }
})

export default actions
