import {
  listResource,
  createResource,
  deleteResource,
} from "../clients/resource"

export async function listGroupRecords(id) {
  if (id) {
    return listResource(`groups/${id}/members`)
  }

  return listResource("members")
}

export async function listSeasonRecords(id) {
  if (id) {
    return listResource(`seasons/${id}/members`)
  }

  return listResource("members")
}

export async function createRecord(groupId, userId) {
  return createResource(`groups/${groupId}/members`, { member: { userId } })
}

export async function destroyRecord(id) {
  return deleteResource("members", id)
}

// Return a list of users who are not already in the group
//
// groupMembers - members data from redux store
export function findUsersFromMembers(groupMembers = []) {
  return groupMembers.map((member) => member.attributes.userId)
}
