import authenticatedRequest from "./authenticatedRequest"
import unauthenticatedRequest from "./unauthenticatedRequest"

// Get the active user's information
export async function activeUser() {
  return authenticatedRequest("/me", "GET", {})
}

// Create a new session - provide email and password
export async function createSession(params = {}) {
  return unauthenticatedRequest("/user_sessions", "POST", params)
}

// Logout currently logged in user.
export async function endSession() {
  return authenticatedRequest("/user_sessions", "DELETE", {})
}
