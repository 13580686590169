const actions = {
  SET_STATE: "memberships/SET_STATE",

  CHANGE_ROLE: "memberships/CHANGE_ROLE",
  CHANGE_ROLE_SUCCESS: "memberships/CHANGE_ROLE_SUCCESS",

  DESTROY: "memberships/DESTROY",
  DESTROY_OK: "memberships/DESTROY_OK",
  DESTROY_FAIL: "memberships/DESTROY_FAIL",
}

export const setMemberships = records => ({
  type: actions.SET_STATE,
  payload: { records },
})

export const changeRole = (id, role, organizationId) => ({
  type: actions.CHANGE_ROLE,
  payload: { id, role, organizationId, saving: id },
})

export const destroyMembership = id => ({
  type: actions.DESTROY,
  payload: { id },
})

export const destroyMembershipSuccess = () => ({
  type: actions.DESTROY_OK,
})

export const destroyMembershipFail = () => ({
  type: actions.DESTROY_FAIL,
})

export default actions
