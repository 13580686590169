const BaseMenu = [
  {
    title: "Dashboard",
    key: "home",
    url: "/",
    icon: "icmn icmn-home utils__spin-delayed--pseudo-selector",
  },
  {
    title: "Profile",
    key: "profile",
    url: "/profile",
    icon: "icmn icmn-user utils__spin-delayed--pseudo-selector",
  },
]

const AdminMenu = [
  {
    title: "Admin",
    key: "lock",
    icon: "icmn icmn-lock",
    children: [
      {
        title: "Organizations",
        key: "organizations",
        url: "/admin/organizations",
        icon: "icmn icmn-office",
      },
      {
        title: "Users",
        key: "users",
        url: "/admin/users",
        icon: "icmn icmn-users",
      },
      {
        title: "Invitations",
        key: "invitations",
        url: "/admin/invitations",
        icon: "icmn icmn-list",
      },
    ],
  },
]

export async function getLeftMenuData(admin) {
  if (admin) {
    return [
      ...BaseMenu,
      { divider: true },
      ...AdminMenu,
    ]
  }

  return BaseMenu
}
export async function getTopMenuData() {
  return [
    {
      title: "Settings",
      key: "settings",
      icon: "icmn icmn-cog utils__spin-delayed--pseudo-selector",
    },
  ]
}
