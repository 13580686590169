import { all, takeEvery, throttle, put, select, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import { notification } from 'antd'
import {
  getRecord,
  listRecords,
  updateRecord,
  createRecord,
  deleteRecord,
  listFacilitatorRecords,
  createAttachment,
  deleteAttachment,
  getLock,
  acquireLockForField,
  releaseLockForField,
} from "services/session"
import { toCamelCase } from "helpers/convertKeys"
import { addSession } from "../groups/actions"
import { getSessions, getCurrentUser } from "../sagas/selectors"

import {
  shouldFetchRecord,
  shouldFetchRecords,
  shouldFetchResource,
  findRecord,
  findRecords,
  makeCall,
  displayErrors,
  requestFailed,
} from "../sagas/utils"
import actions, {
  fetchSingleSuccess,
  fetchSingleFail,
  fetchSessionsSuccess,
  fetchSessionsFail,
  createSessionSuccess,
  createSessionFail,
  destroySessionSuccess,
  destroySessionFail,
  updateSessionSuccess,
  updateSessionFail,
  fetchFacilitatorsSuccess,
  fetchFacilitatorsFail,
  createAttachmentSuccess,
  createAttachmentFail,
  destroyAttachmentSuccess,
  destroyAttachmentFail,
  getLockSuccess,
  getLockFail,
  requestLockSuccess,
  requestLockFail,
  releaseLockSuccess,
  releaseLockFail,
} from "./actions"

function* getState() {
  return yield select(getSessions)
}

function* getCurrentUserState() {
  return yield select(getCurrentUser)
}

export function* FETCH_SINGLE({ payload: { id } }) {
  const sessionState = yield* getState()

  if (shouldFetchRecord(sessionState, id)) {
    yield* makeCall(getRecord, fetchSingleSuccess, fetchSingleFail, id)
    return
  }

  const record = findRecord(sessionState, id)

  yield put(fetchSingleSuccess(record))
}

export function* FETCH_ALL({ payload: { groupId, params } }) {
  const sessionState = yield* getState()

  if (shouldFetchRecords(sessionState, groupId)) {
    yield* makeCall(listRecords, fetchSessionsSuccess, fetchSessionsFail, groupId, params)
    return
  }

  yield put(fetchSessionsSuccess(findRecords(sessionState, groupId)))
}

export function* CREATE({ payload }) {
  const { groupId } = payload
  const success = yield* makeCall(
    createRecord,
    createSessionSuccess,
    createSessionFail,
    payload
  )
  if (success) {
    const { data } = success
    yield put(addSession({ id:  groupId, item: data }))
    yield put(push(`/groups/${groupId}`))
 }
}

export function* DESTROY({ payload: { id } }) {
  const success = yield* makeCall(
    deleteRecord,
    destroySessionSuccess,
    destroySessionFail,
    id
  )

  if (success) {
    notification.success({
      message: "Group Session Removed",
      description: "The session was removed from this group.",
    })
  }
}

function *manageLockFailureOnUpdate(payload, response, error) {
  const currentUserState = yield* getCurrentUserState()
  const { id: userId } = currentUserState
  const { id } = payload
  // TODO: Need less fragile way of determining which fields are locked
  // This is a fragile way of knowing which field you are on
  // Right now, the back end sends errors back in this format and only one RTF can be edited at a time
  const fieldName = error[error.length - 1].split(" is locked for editing")[0].toLowerCase().split(" ").join("_")
  const field = toCamelCase(fieldName)
  const attributes = { lockMessage: "You have lost your lock and must refresh this page to continue editing.  Unsaved changes will be lost." }
  yield put(requestLockFail({ id, field, error, userId, data: { attributes } }))
}

export function* UPDATE({ payload }) {
  const response = yield call(updateRecord, payload)

  if (requestFailed(response)) {
    const { status } = response
    const errors = displayErrors(response)
    yield put(updateSessionFail(errors))
    if (status === 423) {
      yield* manageLockFailureOnUpdate(payload, response, errors)
    }
    return false
  }

  const { data } = response
  yield put(updateSessionSuccess(data))
  return response
}

export function manageFacilitatorsSuccess(id, data) {
  return fetchFacilitatorsSuccess({ id, facilitators: data })
}

function manageFacilitatorsFailure(id, errors) {
  return fetchFacilitatorsFail({ id, error: errors })
}

export function* FETCH_FACILITATORS({ payload: { id } }) {
  const facilitatorsState = yield* getState()

  if (!shouldFetchResource(facilitatorsState, id, "sessions")) { return }

  yield *makeCall(
    listFacilitatorRecords,
    manageFacilitatorsSuccess.bind(this, id),
    manageFacilitatorsFailure.bind(this, id),
    id
  )
}

export function* CREATE_ATTACHMENT({ payload }) {
  yield *makeCall(
    createAttachment,
    createAttachmentSuccess,
    createAttachmentFail,
    payload
  )
}

export function manageDestroyAttachmentSuccess(payload) {
  return destroyAttachmentSuccess(payload)
}

function manageDestroyAttachmentFail(payload, error) {
  const finalPayload = Object.assign({}, payload, { error })
  notification.error({
    message: "Failed to Destroy Attachment",
    description: error.join("."),
  })

  return destroyAttachmentFail(finalPayload)
}

export function* DESTROY_ATTACHMENT({ payload }) {
  yield *makeCall(
    deleteAttachment,
    manageDestroyAttachmentSuccess.bind(this, payload),
    manageDestroyAttachmentFail.bind(this, payload),
    payload
  )
}

export function* UPDATE_AUTO_SAVE({ payload }) {
  yield put({type: 'sessions/UPDATE', payload})
}

export function manageLockSuccess(id, field, userId, action = () => {}, data) {
  return action({ id, field, data, userId })
}

function manageLockFailure(id, field, userId, action = () => {}, errors) {
  return action({ id, field, error: errors, userId })
}

export function* GET_LOCK_INFO({ payload: { id, field } }) {
  const currentUserState = yield* getCurrentUserState()
  const { id: userId } = currentUserState
  yield *makeCall(
    getLock,
    manageLockSuccess.bind(this, id, field, userId, getLockSuccess),
    manageLockFailure.bind(this, id, field, userId, getLockFail),
    { id, field }
  )
}

export function* LOCK_FIELD({ payload: { id, field } }) {
  const currentUserState = yield* getCurrentUserState()
  const { id: userId } = currentUserState
  yield *makeCall(
    acquireLockForField,
    manageLockSuccess.bind(this, id, field, userId, requestLockSuccess),
    manageLockFailure.bind(this, id, field, userId, requestLockFail),
    { id, field }
  )
}

export function* UNLOCK_FIELD({ payload: { id, field } }) {
  const currentUserState = yield* getCurrentUserState()
  const { id: userId } = currentUserState
  yield *makeCall(
    releaseLockForField,
    manageLockSuccess.bind(this, id, field, userId, releaseLockSuccess),
    manageLockFailure.bind(this, id, field, userId, releaseLockFail),
    { id, field }
  )
}

export default function* sessionsSaga() {
  yield all([
    takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE),
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DESTROY, DESTROY),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREATE_ATTACHMENT, CREATE_ATTACHMENT),
    takeEvery(actions.DESTROY_ATTACHMENT, DESTROY_ATTACHMENT),
    takeEvery(actions.FETCH_FACILITATORS, FETCH_FACILITATORS),
    takeEvery(actions.GET_LOCK, GET_LOCK_INFO),
    takeEvery(actions.LOCK, LOCK_FIELD),
    takeEvery(actions.UNLOCK, UNLOCK_FIELD),
    throttle(1000, actions.UPDATE_AUTO_SAVE, UPDATE_AUTO_SAVE),
  ])
}
