import { deleteResource, updateResource } from "clients/resource"

export async function destroy(id) {
  return deleteResource("memberships", id)
}

export async function update(id, role) {
  return updateResource("memberships", id, { membership: { role } })
}
// This is here because yarn complains when the file only has a single item and
// it's not the default export. This file will get more functions and this can
// be removed at that point.
export default destroy
