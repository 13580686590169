import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createHashHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import Honeybadger from "honeybadger-js"
import ErrorBoundary from "@honeybadger-io/react"
import * as serviceWorker from './serviceWorker'

// app styles
import './global.scss'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
let enhancer = null;
let devtools = null;

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
  // eslint-disable-next-line
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
}

if (devtools) {
  enhancer = compose(
    applyMiddleware(...middlewares),
    devtools,
  )
} else {
  enhancer = compose(
    applyMiddleware(...middlewares),
  )
}

const store = createStore(reducers(history), enhancer)

sagaMiddleware.run(sagas)

const environment = process.env.NODE_ENV
const hbConfig = {
  environment,
  api_key: "87706aaa",
  disabled: environment === "development",
}
const honeybadger = Honeybadger.configure(hbConfig)

ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <Provider store={store}>
      <Localization>
        <Router history={history} />
      </Localization>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
)

/*
  This app can enable serviceWorker when:
  a) The user is informed that the app works offline
  b) The user is informed if a new version of the app is available
  c) The redux store persists page refreshes (for READ actions)
  d) C, U, and D actions in CRUD are either disabled or saved until user is back online
  e) If C, U, and D actions are not disabled, the back end is configured to manage save conflicts
*/
serviceWorker.unregister()
export { store, history }
