import { all } from "redux-saga/effects"
import currentUserSaga from "./currentUser/sagas"
import usersSaga from "./users/sagas"
import menuSaga from "./menu/sagas"
import settingsSaga from "./settings/sagas"
import organizationsSaga from "./organizations/sagas"
import membershipsSaga from "./memberships/sagas"
import seasonsSaga from "./seasons/sagas"
import invitationsSaga from "./invitations/sagas"
import groupsSaga from "./groups/sagas"
import membersSaga from "./members/sagas"
import sessionsSaga from "./sessions/sagas"
import facilitatorsSaga from "./facilitators/sagas"

export default function* rootSaga() {
  const sagas = [
    currentUserSaga(),
    usersSaga(),
    menuSaga(),
    settingsSaga(),
    organizationsSaga(),
    membershipsSaga(),
    seasonsSaga(),
    invitationsSaga(),
    groupsSaga(),
    membersSaga(),
    sessionsSaga(),
    facilitatorsSaga(),
  ]

  yield all(sagas)
}
