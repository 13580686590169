import React, { Fragment } from "react"
import { withRouter, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import NProgress from "nprogress"
import { Helmet } from "react-helmet"
import Loader from "components/LayoutComponents/Loader"
import PublicLayout from "./Public"
import LoginLayout from "./Login"
import MainLayout from "./Main"

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  reset: LoginLayout,
}

@withRouter
@connect(({ currentUser }) => ({ currentUser }))
class IndexLayout extends React.PureComponent {
  previousPath = ""

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      currentUser,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    const isUserAuthorized = currentUser.authorized
    const isInvite = pathname.startsWith("/invitations")
    const isReset = pathname.startsWith("/password")

    // Layout Rendering
    const getLayout = () => {
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return "login"
      }
      // Someone is trying to redeem an invitation
      if (isInvite && !isUserAuthorized) {
        return "login"
      }

      if (isReset && !isUserAuthorized) {
        return "login"
      }

      return "main"
    }

    const layout = getLayout()
    const Container = Layouts[layout]
    const isUserLoading = currentUser.loading
    const isLoginLayout = layout === "login"

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }
      // display invitation redeem page for new users following email link --
      // only for non-logged-in users
      if (isInvite && !isUserAuthorized) {
        return <Container>{children}</Container>
      }
      // display password reset page for users following email link --
      // only for non-logged-in users
      if (isReset && !isUserAuthorized) {
        return <Container>{children}</Container>
      }
      // The user is logged in and trying to access an invitation -- they should
      // not be able to do this.
      if (isInvite && isUserAuthorized) {
        return <Redirect to="/profile" />
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isLoginLayout && !isUserAuthorized) {
        return <Redirect to="/user/login" />
      }
      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized) {
        return <Redirect to="/" />
      }
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="PLC Tracker | %s" title="Home" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
