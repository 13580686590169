import actions from "./actions"
import { setResourceIfRecord, updateItemInArray, removeItemInArray, initialState } from "../reducers/utils"

const fetchSingleComplete = (state, action) => {
  const { payload: { record, included } } = action
  const { id } = record
  const organizations = included.filter(x => x.type === "organization")
  const memberships = included.filter(x => x.type === "membership")
  const userWithOrg = setResourceIfRecord({ record, id, resource: "organizations", resourceRecords: organizations })
  const userWithAssociations = setResourceIfRecord({ record: userWithOrg, id, resource: "memberships", resourceRecords: memberships })

  return {
    ...state,
    loading: false,
    record: userWithAssociations,
  }
}

const changeRole = (state, action) => {
  return {
    ...state,
    updateMembershipId: action.payload.id,
  }
}

const changeRoleSuccess = (state, action) => {
  const { payload: { record: membership } } = action
  const { record: user } = state
  const { memberships = [] } = user
  const newMemberships = updateItemInArray(membership, memberships)
  const userWithAssociations = setResourceIfRecord({ record: user, id: user.id, resource: "memberships", resourceRecords: newMemberships })

  return {
    ...state,
    record: userWithAssociations,
    updateMembershipId: null,
  }
}

const changeRoleFailure = (state) => {
  return {
    ...state,
    updateMembershipId: null,
  }
}

const destroyMembership = (state, action) => {
  return {
    ...state,
    updateMembershipId: action.payload.id,
  }
}

const destroyMembershipSuccess = (state) => {
  const deletedId = state.updateMembershipId
  const { record: user } = state
  const { memberships = [] } = user
  const newMemberships = removeItemInArray({id: deletedId }, memberships)
  const userWithAssociations = setResourceIfRecord({ record: user, id: user.id, resource: "memberships", resourceRecords: newMemberships })

  return {
    ...state,
    record: userWithAssociations,
    updateMembershipId: null,
  }
}

const destroyMembershipFailure = (state) => {
  return {
    ...state,
    updateMembershipId: null,
  }
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL:
      return {
        ...state,
        loading: true,
        records: [],
      }

    case actions.FETCH_ALL_OK:
      return {
        ...state,
        loading: false,
        records: action.payload.records,
      }

    case actions.FETCH_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case actions.FETCH_SINGLE:
      return {
        ...state,
        loading: true,
        record: null,
      }

    case actions.FETCH_SINGLE_OK:
      return fetchSingleComplete(state, action)

    case actions.FETCH_SINGLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case actions.CHANGE_ROLE:
      return changeRole(state, action)

    case actions.CHANGE_ROLE_SUCCESS:
      return changeRoleSuccess(state, action)
    case actions.CHANGE_ROLE_FAILURE:
      return changeRoleFailure(state, action)

    case actions.DESTROY_MEMBERSHIP:
      return destroyMembership(state, action)

    case actions.DESTROY_MEMBERSHIP_SUCCESS:
      return destroyMembershipSuccess(state, action)
    case actions.DESTROY_MEMBERSHIP_FAILURE:
      return destroyMembershipFailure(state, action)

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
