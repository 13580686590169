import React from "react"
import { Route, Switch } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
import Loadable from "react-loadable"

import Loader from "components/LayoutComponents/Loader"
import IndexLayout from "layouts"
import NotFoundPage from "pages/404"

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

export const routes = [
  // Main Dashboard
  {
    name: "home",
    path: "/",
    component: loadable(() => import("pages/dashboard")),
    exact: true,
  },

  // Auth
  {
    name: "login",
    path: "/user/login",
    component: loadable(() => import("pages/user/login")),
    exact: true,
  },
  {
    name: "passwordForgot",
    path: "/user/forgot",
    component: loadable(() => import("pages/user/forgot")),
    exact: true,
  },
  {
    name: "passwordReset",
    path: "/password/reset/:id",
    component: loadable(() => import("pages/password/reset")),
    exact: true,
  },
  {
    name: "profile",
    path: "/profile",
    component: loadable(() => import("pages/profile")),
    exact: true,
  },

  // TODO: Remove this in favor of real dashboard.
  // Admin area
  {
    name: "adminOrganizationsIndex",
    path: "/admin/organizations",
    component: loadable(() => import("pages/admin/organizations/list")),
    exact: true,
  },
  {
    name: "adminOrganizationsNew",
    path: "/admin/organizations/new",
    component: loadable(() => import("pages/admin/organizations/new")),
    exact: true,
  },
  {
    name: "adminOrganizationsEdit",
    path: "/admin/organizations/:id/edit",
    component: loadable(() => import("pages/admin/organizations/edit")),
  },
  {
    name: "adminUsersIndex",
    path: "/admin/users",
    component: loadable(() => import("pages/admin/users/list")),
    exact: true,
  },
  {
    name: "adminInvitationsNew",
    path: "/admin/users/invitations/new",
    component: loadable(() => import("pages/admin/invitations/new")),
    exact: true,
  },
  {
    name: "adminInvitationsList",
    path: "/admin/invitations",
    component: loadable(() => import("pages/invitations/list")),
    exact: true,
  },
  {
    name: "adminUsersShow",
    path: "/admin/users/:id",
    component: loadable(() => import("pages/admin/users/show")),
  },
  // Redeem invitations for new users (logged in users shouldn't use this)
  {
    name: "invitationsShow",
    path: "/invitations/:id",
    component: loadable(() => import("pages/invitations/redeem")),
  },
  // Sessions
  {
    name: "sessionsNew",
    path: "/groups/:id/sessions/new",
    component: loadable(() => import("pages/sessions/new"))
  },
  {
    name: "sessionsEdit",
    path: "/sessions/:id/edit",
    component: loadable(() => import("pages/sessions/edit"))
  },
  {
    name: "sessionsDocuments",
    path: "/sessions/:id/:doctype",
    component: loadable(() => import("pages/sessions/documents"))
  },
  {
    name: "sessionsShow",
    path: "/sessions/:id",
    component: loadable(() => import("pages/sessions/show"))
  },
  // Seasons
  {
    name: "seasonsNew",
    path: "/organizations/:id/seasons/new",
    component: loadable(() => import("pages/seasons/new"))
  },
  {
    name: "seasonsShow",
    path: "/seasons/:id",
    component: loadable(() => import("pages/seasons/show")),
    exact: true,
  },
  // Groups
  {
    name: "groupsNew",
    path: "/seasons/:id/groups/new",
    component: loadable(() => import("pages/groups/new"))
  },
  {
    name: "seasonGroupsShow",
    path: "/seasons/:seasonId/groups/:id",
    component: loadable(() => import("pages/groups/show")),
    exact: true,
  },
  {
    name: "groupsEdit",
    path: "/seasons/:seasonId/groups/:id/edit",
    component: loadable(() => import("pages/groups/edit"))
  },

  {
    name: "sessionsIndex",
    path: "/groups/:id/sessions",
    component: loadable(() => import("pages/sessions/list")),
  },
  {
    name: "groupsShow",
    path: "/groups/:id",
    component: loadable(() => import("pages/groups/show")),
    exact: true,
  },

  {
    name: "orgInvitationsNew",
    path: "/organizations/:id/invitations/new",
    component: loadable(() => import("pages/invitations/new")),
  },
  {
    name: "organizationsShow",
    path: "/organizations/:id",
    component: loadable(() => import("pages/organizations/show")),
  },
]

export default function Router({ history }) {
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          {routes.map(route => (
            <Route {...route} key={route.path} />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  )
}