const actions = {
  SET_STATE: "groups/SET_STATE",
  FETCH_SINGLE: "groups/FETCH_SINGLE",
  FETCH_SINGLE_SUCCESS: "groups/FETCH_SINGLE_SUCCESS",
  FETCH_SINGLE_FAIL: "groups/FETCH_SINGLE_FAIL",
  FETCH_ALL: "groups/FETCH_ALL",
  FETCH_ALL_OK: "groups/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "groups/FETCH_ALL_FAIL",
  CREATE: "groups/CREATE",
  CREATE_SUCCESS: "groups/CREATE_SUCCESS",
  CREATE_FAIL: "groups/CREATE_FAIL",
  DELETE: "groups/DELETE",
  DELETE_OK: "groups/DELETE_OK",
  DELETE_FAIL: "groups/DELETE_FAIL",
  UPDATE: "groups/UPDATE",
  UPDATE_SUCCESS: "groups/UPDATE_SUCCESS",
  UPDATE_FAIL: "groups/UPDATE_FAIL",
  FETCH_MEMBERS: "groups/FETCH_MEMBERS",
  FETCH_MEMBERS_SUCCESS: "groups/FETCH_MEMBERS_SUCCESS",
  FETCH_MEMBERS_FAIL: "groups/FETCH_MEMBERS_FAIL",
  ADD_MEMBER: "groups/ADD_MEMBER",
  REMOVE_MEMBER: "groups/REMOVE_MEMBER",
  FETCH_SESSIONS: "groups/FETCH_SESSIONS",
  FETCH_SESSIONS_SUCCESS: "groups/FETCH_SESSIONS_SUCCESS",
  FETCH_SESSIONS_FAIL: "groups/FETCH_SESSIONS_FAIL",
  ADD_SESSION: "groups/ADD_SESSION",
  REMOVE_SESSION: "groups/REMOVE_SESSION",
}

export const fetchSingle = payload => ({
  type: actions.FETCH_SINGLE,
  payload,
})

export const fetchSingleSuccess = record => ({
  type: actions.FETCH_SINGLE_SUCCESS,
  payload: { record },
})

export const fetchSingleFail = error => ({
  type: actions.FETCH_SINGLE_FAIL,
  payload: { error },
})

export const fetchGroups = seasonId => ({
  type: actions.FETCH_ALL,
  payload: { seasonId, id: seasonId },
})

export const fetchGroupsSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchGroupsFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const createGroup = payload => ({
  type: actions.CREATE,
  payload,
})

export const createGroupSuccess = record => ({
  type: actions.CREATE_SUCCESS,
  payload: { record },
})

export const createGroupFail = error => ({
  type: actions.CREATE_FAIL,
  payload: { error },
})

export const deleteGroup =  (seasonId, id) => ({
  type: actions.DELETE,
  payload: { seasonId, id },
})

export const deleteGroupSuccess = () => ({
  type: actions.DELETE_OK,
  payload: {},
})

export const deleteGroupFail = error => ({
  type: actions.DELETE_FAIL,
  payload: { error },
})

export const updateGroup = payload => ({
  type: actions.UPDATE,
  payload,
})

export const updateGroupSuccess = record => ({
  type: actions.UPDATE_SUCCESS,
  payload: { record },
})

export const updateGroupFail = error => ({
  type: actions.UPDATE_FAIL,
  payload: { error },
})

export const fetchMembers = id => ({
  type: actions.FETCH_MEMBERS,
  payload: { id },
})

export const fetchMembersSuccess = payload => ({
  type: actions.FETCH_MEMBERS_SUCCESS,
  payload,
})

export const fetchMembersFail = payload => ({
  type: actions.FETCH_MEMBERS_FAIL,
  payload,
})

export const removeMember = payload => ({
  type: actions.REMOVE_MEMBER,
  payload,
})

export const addMember = payload => ({
  type: actions.ADD_MEMBER,
  payload,
})

export const fetchSessions = id => ({
  type: actions.FETCH_SESSIONS,
  payload: { id },
})

export const fetchSessionsSuccess = payload => ({
  type: actions.FETCH_SESSIONS_SUCCESS,
  payload,
})

export const fetchSessionsFail = payload => ({
  type: actions.FETCH_SESSIONS_FAIL,
  payload,
})

export const removeSession = payload => ({
  type: actions.REMOVE_SESSION,
  payload,
})

export const addSession = payload => ({
  type: actions.ADD_SESSION,
  payload,
})

export default actions