import actions from "./actions"

// This is used as both a seed when no user is logged in, and to reset
// currentUser state on logout. Be sure to consider how logout is affected when
// adding new state here.
export const LoggedOutUser = {
  id: "",
  name: "",
  role: "",
  email: "",
  avatar: "",
  organizations: [],
  invitations: [],
  invitationOrganizations: [],
  currentOrganizationId: null,
  authorized: false,

  saving: false,
  loading: false,
}

export default function currentUserReducer(state = LoggedOutUser, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
