const actions = {
  SET_STATE: "sessions/SET_STATE",
  FETCH_SINGLE: "sessions/FETCH_SINGLE",
  FETCH_SINGLE_SUCCESS: "sessions/FETCH_SINGLE_SUCCESS",
  FETCH_SINGLE_FAIL: "sessions/FETCH_SINGLE_FAIL",
  FETCH_ALL: "sessions/FETCH_ALL",
  FETCH_ALL_OK: "sessions/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "sessions/FETCH_ALL_FAIL",
  CREATE: "sessions/CREATE",
  CREATE_SUCCESS: "sessions/CREATE_SUCCESS",
  CREATE_FAIL: "sessions/CREATE_FAIL",
  DESTROY: "sessions/DESTROY",
  DESTROY_OK: "sessions/DESTROY_OK",
  DESTROY_FAIL: "sessions/DESTROY_FAIL",
  UPDATE: "sessions/UPDATE",
  UPDATE_AUTO_SAVE: "sessions/UPDATE_AUTO_SAVE",
  UPDATE_SUCCESS: "sessions/UPDATE_SUCCESS",
  UPDATE_FAIL: "sessions/UPDATE_FAIL",
  FETCH_FACILITATORS: "sessions/FETCH_FACILITATORS",
  FETCH_FACILITATORS_SUCCESS: "sessions/FETCH_FACILITATORS_SUCCESS",
  FETCH_FACILITATORS_FAIL: "sessions/FETCH_FACILITATORS_FAIL",
  ADD_FACILITATOR: "sessions/ADD_FACILITATOR",
  REMOVE_FACILITATOR: "sessions/REMOVE_FACILITATOR",
  CREATE_ATTACHMENT: "sessions/CREATE_ATTACHMENT",
  CREATE_ATTACHMENT_SUCCESS: "sessions/CREATE_ATTACHMENT_SUCCESS",
  CREATE_ATTACHMENT_FAIL: "sessions/CREATE_ATTACHMENT_FAIL",
  DESTROY_ATTACHMENT: "sessions/DESTROY_ATTACHMENT",
  DESTROY_ATTACHMENT_SUCCESS: "sessions/DESTROY_ATTACHMENT_SUCCESS",
  DESTROY_ATTACHMENT_FAIL: "sessions/DESTROY_ATTACHMENT_FAIL",
  GET_LOCK: "sessions/GET_LOCK",
  GET_LOCK_SUCCESS: "sessions/GET_LOCK_SUCCESS",
  GET_LOCK_FAIL: "sessions/GET_LOCK_FAIL",
  LOCK: "sessions/LOCK",
  LOCK_SUCCESS: "sessions/LOCK_SUCCESS",
  LOCK_FAIL: "sessions/LOCK_FAIL",
  UNLOCK: "sessions/UNLOCK",
  UNLOCK_SUCCESS: "sessions/UNLOCK_SUCCESS",
  UNLOCK_FAIL: "sessions/UNLOCK_FAIL",
}

export const clearErrors = () => ({
  type: actions.SET_STATE,
  payload: { error: null }
})

export const destroySession =  id => ({
  type: actions.DESTROY,
  payload: { id },
})

export const destroySessionSuccess = () => ({
  type: actions.DESTROY_OK,
  payload: {},
})

export const destroySessionFail = error => ({
  type: actions.DESTROY_FAIL,
  payload: { error },
})

export const fetchSingle = payload => ({
  type: actions.FETCH_SINGLE,
  payload,
})

export const fetchSingleSuccess = record => ({
  type: actions.FETCH_SINGLE_SUCCESS,
  payload: { record },
})

export const fetchSingleFail = error => ({
  type: actions.FETCH_SINGLE_FAIL,
  payload: { error },
})

export const fetchSessions = (groupId, params = {}) => ({
  type: actions.FETCH_ALL,
  payload: { groupId, id: groupId, params },
})

export const fetchSessionsSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchSessionsFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const createSession = payload => ({
  type: actions.CREATE,
  payload,
})

export const createSessionSuccess = record => ({
  type: actions.CREATE_SUCCESS,
  payload: { record },
})

export const createSessionFail = error => ({
  type: actions.CREATE_FAIL,
  payload: { error },
})

export const updateSession = payload => ({
  type: actions.UPDATE,
  payload,
})

export const updateAutoSave = payload => ({
  type: actions.UPDATE_AUTO_SAVE,
  payload,
})

export const updateSessionSuccess = record => ({
  type: actions.UPDATE_SUCCESS,
  payload: { record },
})

export const updateSessionFail = error => ({
  type: actions.UPDATE_FAIL,
  payload: { error },
})

export const fetchFacilitators = id => ({
  type: actions.FETCH_FACILITATORS,
  payload: { id },
})

export const fetchFacilitatorsSuccess = payload => ({
  type: actions.FETCH_FACILITATORS_SUCCESS,
  payload,
})

export const fetchFacilitatorsFail = payload => ({
  type: actions.FETCH_FACILITATORS_FAIL,
  payload,
})

export const removeFacilitator = payload => ({
  type: actions.REMOVE_FACILITATOR,
  payload,
})

export const addFacilitator = payload => ({
  type: actions.ADD_FACILITATOR,
  payload,
})

export const createAttachment = payload => ({
  type: actions.CREATE_ATTACHMENT,
  payload,
})

export const createAttachmentSuccess = payload => ({
  type: actions.CREATE_ATTACHMENT_SUCCESS,
  payload,
})

export const createAttachmentFail = error => ({
  type: actions.CREATE_ATTACHMENT_FAIL,
  payload: { error },
})

export const destroyAttachment = payload => ({
  type: actions.DESTROY_ATTACHMENT,
  payload,
})

export const destroyAttachmentSuccess = payload => ({
  type: actions.DESTROY_ATTACHMENT_SUCCESS,
  payload,
})

export const destroyAttachmentFail = payload => ({
  type: actions.DESTROY_ATTACHMENT_FAIL,
  payload,
})


export const getLockInfo = payload => ({
  type: actions.GET_LOCK,
  payload,
})

export const getLockSuccess = (payload) => ({
  type: actions.GET_LOCK_SUCCESS,
  payload,
})

export const getLockFail = payload => ({
  type: actions.GET_LOCK_FAIL,
  payload,
})

export const requestLock = payload => ({
  type: actions.LOCK,
  payload,
})

export const requestLockSuccess = (payload) => ({
  type: actions.LOCK_SUCCESS,
  payload,
})

export const requestLockFail = payload => ({
  type: actions.LOCK_FAIL,
  payload,
})

export const releaseLock = payload => ({
  type: actions.UNLOCK,
  payload,
})

export const releaseLockSuccess = (payload) => ({
  type: actions.UNLOCK_SUCCESS,
  payload,
})

export const releaseLockFail = error => ({
  type: actions.UNLOCK_FAIL,
  payload: { error },
})

export default actions