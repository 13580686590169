const actions = {
  SET_STATE: "invitations/SET_STATE",

  CREATE: "invitations/CREATE",
  CREATE_OK: "invitations/CREATE_OK",
  CREATE_FAIL: "invitations/CREATE_FAIL",

  DELETE: "invitations/DELETE",
  DELETE_OK: "invitations/DELETE_OK",
  DELETE_FAIL: "invitations/DELETE_FAIL",

  FETCH_ALL: "invitations/FETCH_ALL",
  FETCH_ALL_OK: "invitations/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "invitations/FETCH_ALL_FAIL",

  REDEEM: "invitations/REDEEM",
  REDEEM_OK: "invitations/REDEEM_OK",
  REDEEM_FAIL: "invitations/REDEEM_FAIL",

  ACCEPT_ORGANIZATION_INVITATION: "invitations/ACCEPT_ORGANIZATION_INVITATION",
  ACCEPT_ORGANIZATION_INVITATION_FAIL: "invitations/ACCEPT_ORGANIZATION_INVITATION_FAIL",
  ACCEPT_ORGANIZATION_INVITATION_OK: "invitations/ACCEPT_ORGANIZATION_INVITATION_OK"
}

export const setInvitations = records => ({
  type: actions.SET_STATE,
  payload: { records },
})

export const createInvitation = record => ({
  type: actions.CREATE,
  payload: { record },
})

export const createInvitationSuccess = () => ({
  type: actions.CREATE_OK,
})

export const createInvitationFail = error => ({
  type: actions.CREATE_FAIL,
  payload: { error },
})

export const deleteInvitation =  (id) => ({
  type: actions.DELETE,
  payload: { id },
})

export const deleteInvitationSuccess = () => ({
  type: actions.DELETE_OK,
  payload: {},
})

export const deleteInvitationFail = error => ({
  type: actions.DELETE_FAIL,
  payload: { error },
})

export const fetchInvitations = () => ({
  type: actions.FETCH_ALL,
})

export const fetchInvitationsSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchInvitationsFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const redeemInvitation = payload => ({
  type: actions.REDEEM,
  payload,
})

export const redeemInvitationSuccess = () => ({
  type: actions.REDEEM_OK,
})

export const redeemInvitationFail = error => ({
  type: actions.REDEEM_FAIL,
  payload: { error },
})

export const acceptOrganizationInvitation = (id) => ({
  type: actions.ACCEPT_ORGANIZATION_INVITATION,
  payload: { id },
})

export const acceptOrganizationInvitationFail = error => ({
  type: actions.ACCEPT_ORGANIZATION_INVITATION_FAIL,
  payload: { error },
})

export const acceptOrganizationInvitationSuccess = () => ({
  type: actions.ACCEPT_ORGANIZATION_INVITATION_OK,
})

export default actions
