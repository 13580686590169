import moment from "moment"
import {
  getResource,
  listResource,
  updateResource,
  createResource,
  deleteResource,
} from "../clients/resource"
import { routes } from "../router"

export async function listRecords(id, params = {}) {
  if (id) {
    return listResource(`groups/${id}/sessions`, params)
  }

  return listResource("sessions", params)
}

export async function createRecord(params) {
  return createResource("sessions", { group_session: params })
}

export async function deleteRecord(id) {
  return deleteResource("sessions", id)
}

export async function getRecord(id) {
  return getResource("sessions", id)
}

export async function updateRecord(params) {
  const { id }  = params
  return updateResource("sessions", id, { group_session: params })
}

export async function listFacilitatorRecords(id) {
  if (id) {
    return listResource(`sessions/${id}/facilitators`)
  }

  return listResource("facilitators")
}

export async function getLock(params) {
  const { id, field }  = params
  return getResource(`sessions/${id}/rich_text_locks`, field)
}

export async function acquireLockForField(params) {
  const { id, field }  = params
  return createResource(`sessions/${id}/rich_text_locks`, { group_session: { richTextField: field } })
}

export async function releaseLockForField(params) {
  const { id, field }  = params
  return deleteResource(`sessions/${id}/rich_text_locks`, field)
}

export async function presignedAttachment(params) {
  const { id }  = params
  if (id) {
    return createResource(`sessions/${id}/presigned_attachments`, params)
  }

  return createResource("presigned_attachments")
}

export async function createAttachment(params) {
  const { id }  = params
  if (id) {
    return createResource(`sessions/${id}/attachments`, params)
  }

  return createResource("attachments")
}

export async function deleteAttachment(params) {
  const { id, sessionId }  = params
  return deleteResource(`sessions/${sessionId}/attachments`, id)
}

export function findGroupId(session) {
  if (!session) {
    return null;
  }

  if (session.groupId) {
    return session.groupId
  }

  return session.relationships.group.data.id;
}

export function sessionRoute(routeName, session) {
  const route = routes.find(r => r.name === routeName)

  const { path } = route
  const pathSegments = path.split("/")
  const routeSegments = pathSegments.map(segment => {
    if (segment.startsWith(":")) {
      switch (segment) {
        case ":id":
          return session.id || findGroupId(session)
        case ":groupId":
          return findGroupId(session)
        default:
          return session.id
      }
    }

    return segment
  })

  return `${routeSegments.join("/")}`
}

export function scheduledTime(session) {
  const { startAt } =  session?.attributes || session || {} 
  if (!session || !startAt) { return "unscheduled session" }

  return moment(startAt).format("MMMM Do [at] h:mm a")
}

// Return a list of users who are not already facilitators in the session
//
// groupMembers - members data from redux store
// facilitators - facilitators data from redux store
export function findSpectators(groupMembers = [], facilitators = []) {
  const facilitatorIds = facilitators.map((facilitator) => facilitator.attributes.userId)
  const spectators = groupMembers.filter((user) => !facilitatorIds.includes(user.attributes.userId))

  return spectators;
}

