import { all, takeEvery, put, select } from "redux-saga/effects"
import { push } from "connected-react-router"
import { notification } from 'antd'
import { listRecords, getRecord, updateRecord, createRecord, deleteRecord, listMemberRecords, listSessionRecords } from "services/group"
import { pathFor } from "services/util"
import { getGroups } from "../sagas/selectors"
import {
  shouldFetchRecord,
  shouldFetchRecords,
  shouldFetchResource,
  findRecord,
  findRecords,
  makeCall,
} from "../sagas/utils"
import actions, {
  fetchSingleSuccess,
  fetchSingleFail,
  fetchGroupsSuccess,
  fetchGroupsFail,
  createGroupSuccess,
  createGroupFail,
  deleteGroupSuccess,
  deleteGroupFail,
  updateGroupSuccess,
  updateGroupFail,
  fetchMembersSuccess,
  fetchMembersFail,
  fetchSessionsSuccess,
  fetchSessionsFail,
} from "./actions"

function* getState() {
  return yield select(getGroups)
}

export function* FETCH_SINGLE({ payload: { id, seasonId } }) {
  const groupState = yield* getState()

  if (shouldFetchRecord(groupState, id)) {
    yield* makeCall(getRecord, fetchSingleSuccess, fetchSingleFail, seasonId, id)
    return
  }

  const record = findRecord(groupState, id)

  yield put(fetchSingleSuccess(record))
}

export function* FETCH_ALL({ payload: { seasonId } }) {
  const groupState = yield* getState()

  if (shouldFetchRecords(groupState, seasonId)) {
    yield* makeCall(listRecords, fetchGroupsSuccess, fetchGroupsFail, seasonId)
    return
  }

  yield put(fetchGroupsSuccess(findRecords(groupState, seasonId)))
}

export function* CREATE({ payload }) {
  const { seasonId, name, goal, guidingQuestion } = payload
  const success = yield* makeCall(
    createRecord,
    createGroupSuccess,
    createGroupFail,
    seasonId, name, goal, guidingQuestion
  )
  if (success) {
    const { data: { id } } = success
    yield put(push(pathFor("groupsShow", { id, seasonId })))
 }
}

function handleDeleteFailure(errors) {
  notification.warning({
    message: "Request Failed",
    description: errors,
  })
  return deleteGroupFail(errors)
}

export function* DELETE({ payload }) {
  const { seasonId, id } = payload
  const success = yield* makeCall(
    deleteRecord,
    deleteGroupSuccess,
    handleDeleteFailure,
    seasonId,
    id
  )

  if (success) {
    notification.success({
      message: "Group deleted",
      description: "The group was deleted from this season.",
    })
  }
}

export function* UPDATE({ payload }) {
  const { seasonId, id, name, goal, guidingQuestion } = payload

  const success = yield* makeCall(
    updateRecord,
    updateGroupSuccess,
    updateGroupFail,
    id, seasonId, name, goal, guidingQuestion
  )
  if (success) {
    yield put(push(pathFor("groupsShow", { id, seasonId })))
  }
}

export function manageMembersSuccess(id, data) {
  return fetchMembersSuccess({ id, members: data })
}

function manageMembersFailure(id, errors) {
  return fetchMembersFail({ id, error: errors })
}

export function* FETCH_MEMBERS({ payload: { id } }) {
  const groupState = yield* getState()

  if (!shouldFetchResource(groupState, id, "members")) { return }

  yield *makeCall(
    listMemberRecords,
    manageMembersSuccess.bind(this, id),
    manageMembersFailure.bind(this, id),
    id
  )
}

export function manageSessionsSuccess(id, data) {
  return fetchSessionsSuccess({ id, sessions: data })
}

function manageSessionsFailure(id, errors) {
  return fetchSessionsFail({ id, error: errors })
}

export function* FETCH_SESSIONS({ payload: { id } }) {
  const groupState = yield* getState()

  if (!shouldFetchResource(groupState, id, "sessions")) { return }

  yield *makeCall(
    listSessionRecords,
    manageSessionsSuccess.bind(this, id),
    manageSessionsFailure.bind(this, id),
    id
  )
}

export default function* groupsSaga() {
  yield all([
    takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.FETCH_MEMBERS, FETCH_MEMBERS),
    takeEvery(actions.FETCH_SESSIONS, FETCH_SESSIONS),
  ])
}
