import unauthenticatedRequest from "clients/unauthenticatedRequest"
import {
  createResource,
  deleteResource,
  listResource,
  updateUnauthenticated,
  updateAuthenticated,
} from "clients/resource"

// invitation : { email, role?, organizationId? }
// determines which invitation endpoint to post to based on presence of organizationId
export async function create(invitation) {
  const id = invitation.organizationId
  const url = id ? `organizations/${id}/invite` : "invitations/admin"

  return createResource(url, { invitation })
}

export async function deleteRecord(id) {
  return deleteResource("invitations", id)
}

export async function getPublic(id) {
  return unauthenticatedRequest(`/invitations/${id}/public`, "GET", {})
}

export async function listRecords() {
  return listResource("invitations")
}

export async function redeem(id, invitation = {}) {
  return updateUnauthenticated(`invitations/${id}/redeem/public`, { invitation })
}

export async function accept(id) {
  return updateAuthenticated(`invitations/${id}/redeem`, {})
}

export default create
