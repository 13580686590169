import actions from "./actions"
import {
  createReducer,
  createReducerComplete,
  updateReducer,
  updateReducerComplete,
} from "../reducers/utils"

const initialState = {
  records: [],
  record: null,
  id: null,

  loading: false,
  saving: false,
  error: null,

  editing: false,
};

export default function organizationsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SINGLE:
      return {
        ...state,
        loading: true,
      }

    case actions.FETCH_SINGLE_OK:
      return {
        ...state,
        loading: false,
        record: action.payload.record,
        id: action.payload.record.id,
      }

    case actions.FETCH_SINGLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case actions.FETCH_ALL:
      return {
        ...state,
        loading: true,
      }

    case actions.FETCH_ALL_OK:
      return {
        ...state,
        loading: false,
        records: action.payload.records,
      }

    case actions.FETCH_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.UPDATE:
      return updateReducer(state, action)

    case actions.UPDATE_SUCCESS:
    case actions.UPDATE_FAIL:
      return updateReducerComplete(state, action)

    case actions.CREATE:
      return createReducer(state, action)

    case actions.CREATE_SUCCESS:
    case actions.CREATE_FAIL:
      return createReducerComplete(state, action)

    default:
      return state
  }
}
