import React from 'react'
import { Button, Menu, Dropdown } from 'antd'
import styles from './style.module.scss'

function OrganizationSwitcher({ user: { currentOrganizationId, saving, organizations = [] }, onSwitch }) {
  if (organizations.length === 0) {
    return null
  }

  const items = organizations.map((org) => {
    return (
      <Menu.Item className={styles.item} key={org.id}>
        <i className={`${styles.icon} icmn-office`} />
        <div className={styles.inner}>
          <div className={styles.title}>
            <span className="pull-right">
              {currentOrganizationId === org.id && <i className="icmn-checkmark" />}
              {currentOrganizationId !== org.id &&  <Button loading={saving} onClick={() => onSwitch(org.id)} size="small" icon="swap" />}
            </span>
            <a> {org.attributes.name} </a>
          </div>
        </div>
      </Menu.Item>
    )
  })

  const menu = (
    <Menu selectable={false} className={styles.activity}>
      {items}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <i className="icmn-office topbar__dropdownIcon" />
      </div>
    </Dropdown>
  )
}

export default OrganizationSwitcher
