import actions from "./actions"
import {
  deleteReducer,
  deleteReducerComplete,
  getAllReducerComplete,
  removeResourceItemReducer,
} from "../reducers/utils"

const initialState = {
  records: [],
  record: {},
  id: null,

  loading: false,
  saving: false,
  error: null,
}

export default function invitationsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE:
    case actions.ACCEPT_ORGANIZATION_INVITATION:
    case actions.REDEEM:
      return {
        ...state,
        record: action.payload.record,
        saving: true,
      }

    case actions.CREATE_OK:
    case actions.ACCEPT_ORGANIZATION_INVITATION_OK:
    case actions.REDEEM_OK:
      return {
        ...state,
        saving: false,
      }

    case actions.CREATE_FAIL:
    case actions.ACCEPT_ORGANIZATION_INVITATION_FAIL:
    case actions.REDEEM_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload.error,
      }

    case actions.DELETE:
      return deleteReducer(state, action)

    case actions.DELETE_OK:
    case actions.DELETE_FAIL:
      return deleteReducerComplete(state, action)

    case actions.FETCH_ALL:
      return {
        ...state,
        loading: true,
      }

    case actions.FETCH_ALL_OK:
    case actions.FETCH_ALL_FAIL:
      return getAllReducerComplete(state, action)

    case actions.REMOVE_INVITATION:
      return removeResourceItemReducer(state,  action,  "invitations")

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
