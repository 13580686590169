import actions from "./actions"

const initialState = {
  id: null,
  record: null,
  records: [],

  loading: false,
  loadingError: null, // for Fetch actions, which might require redirects

  error: null,       // for Save/Create actions, which require display
  saving: false,
}

export default function membersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.FETCH_ALL:
      return {
        ...state,
        record: null,
        error: null,
        loadingError: null,

        loading: true,
        records: [],
      }

    case actions.FETCH_ALL_OK:
      return {
        ...state,
        loading: false,
        records: action.payload.records,
      }

    case actions.FETCH_ALL_FAIL:
      return {
        ...state,
        loading: false,
        loadingError: action.payload.error,
      }

    case actions.CREATE:
      return {
        ...state,
        error: null,
        record: null,

        saving: true,
      }

    case actions.CREATE_SUCCESS:
      return {
        ...state,
        saving: false,
        record: action.payload.record,
        records: state.records.concat([action.payload.record]),
      }

    case actions.CREATE_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload.error,
      }

    case actions.DESTROY:
      return {
        ...state,
        id: action.payload.id,
        saving: action.payload.id,
      }

    case actions.DESTROY_SUCCESS:
      return {
        ...state,
        id: null,
        saving: false,
        records: state.records.filter(rec => rec.id !== state.id)
      }

    case actions.DESTROY_FAIL:
      return {
        ...state,
        id: null,
        saving: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}