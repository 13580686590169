import { notification } from "antd"
import { push } from "connected-react-router"
import { all, takeEvery, put, call, select } from "redux-saga/effects"
import {
  getRecord,
  createRecord,
  deleteRecord,
  listRecords,
  listMemberRecords,
  updateRecord,
} from "services/season"
import { makeCall } from "../sagas/utils"
import actions, {
  deleteSeasonSuccess,
  deleteSeasonFail,
  fetchSeasonSuccess,
  fetchSeasonFail,
  fetchSeasonsSuccess,
  fetchSeasonsFail,
  fetchMembersSuccess,
  fetchMembersFail,
} from "./actions"

function* updateSeasonData(data) {
  yield put(fetchSeasonSuccess(data))
}

export function* FETCH_SINGLE({ payload: { id } }) {
  const records = yield select((state) => state.seasons.records)
  const foundRecord = records.find(rec => rec.id  === id)
  if (foundRecord) {
    yield(put(fetchSeasonSuccess(foundRecord)))
    return
  }

  const { data, errors = [] } = yield call(getRecord, id)

  if (errors.length > 0) {
    yield put(fetchSeasonFail(errors))
    return
  }

  yield call(updateSeasonData, data)
}

export function* CREATE({ payload }) {
  const { id, name, startOn } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: true,
    },
  })

  const response = yield call(createRecord, id, name, startOn)

  if (response.status === 201) {
    const seasonId = response.data.id
    notification.success({
      message: "Created Season",
      description: "The season has been created!",
    })
    yield put(push(`/seasons/${seasonId}`))
  } else {
    const { errors } = response
    notification.error({
      message: "Something went wrong",
      description: errors,
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: false,
    },
  })
}

export function* UPDATE({ payload }) {
  const { id } = payload

  const response = yield call(updateRecord, id, payload)

  if (response.status === 200) {
    notification.success({
      message: "Updated Season",
      description: "The season has been updated!",
    })
  } else {
    const { errors } = response
    notification.error({
      message: "Something went wrong",
      description: errors,
    })

    yield put({
      type: actions.SET_STATE,
      payload: {
        saving: false,
      },
    })

    return
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: false,
      record: response.data,
    },
  })
}

function handleDeleteFailure(errors) {
  notification.warning({
    message: "Request Failed",
    description: errors,
  })
  return deleteSeasonFail(errors)
}

export function* DELETE({ payload }) {
  const { id } = payload
  const success = yield* makeCall(
    deleteRecord,
    deleteSeasonSuccess,
    handleDeleteFailure,
    id
  )

  if (success) {
    notification.success({
      message: "Season deleted",
      description: "The season was removed from this organization.",
    })
  }
}

export function* FETCH_ALL({ payload: { organizationId, params = {} } }) {
  const { data, errors = [] } = yield call(listRecords, organizationId, params)

  if (errors.length > 0) {
    yield put(fetchSeasonsFail(errors))
    return
  }

  yield put(fetchSeasonsSuccess(data))
}

export function* FETCH_MEMBERS({ payload: { id } }) {
  const { data, errors = [] } = yield call(listMemberRecords, id)

  if (errors.length > 0) {
    yield put(fetchMembersFail({ id, error: errors }))
    return
  }

  yield put(fetchMembersSuccess({ id, members: data }))
}


export default function* seasonsSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE),
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.FETCH_MEMBERS, FETCH_MEMBERS),
  ])
}
