import {
  listResource,
  getResource,
  createResource,
  updateResource,
  deleteResource,
} from "../clients/resource"

export async function createRecord(name, description) {
  return createResource("organizations", { organization: { name, description } })
}

export async function listRecords() {
  return listResource("organizations")
}

export async function getRecord(id) {
  return getResource("organizations", id)
}

export async function updateRecord(id, name, description) {
  return updateResource("organizations", id, { organization: { name, description } })
}

export async function deleteRecord(params) {
  return deleteResource("organizations", params.id)
}

// Return a list of associated users and their membership records for a given
// organization.
//
// organization - the org to query for this data
// users - user data from redux store
// memberships - membership data from redux store
export function findOrganizationMemberships(organization, users, memberships) {
  const { relationships } = organization
  let memships = {}
  let ship

  // Find all membership records that correspond to memberships that belong to
  // this organization, indexed by user id.
  if (relationships.memberships && relationships.memberships.data && relationships.memberships.data.length > 0) {
    memships = relationships.memberships.data.reduce((memo, ms) => {
      ship = memberships.find(m => m.id === ms.id)
      if (ship) {
        memo[ship.attributes.userId] = ship
      }
      return memo
    }, {})
  }

  // Find a list of all user objects that correspond to the membership records.
  const userIds = Object.keys(memships)
  let orgUsers = []
  if (userIds.length > 0 && users.length > 0) {
    orgUsers = userIds.map(userId => {
      return users.find(u => u.id === userId)
    })
  }

  return [memships, orgUsers]
}
