import { all, takeEvery, put, call } from "redux-saga/effects"
import { notification } from "antd"
import { listRecords, getRecord } from "services/user"
import { destroy, update } from "services/memberships"
import {
  makeCall,
} from "../sagas/utils"
import actions, {
  fetchUsersSuccess,
  fetchUsersFail,
  fetchUserSuccess,
  fetchUserFail,
  changeRoleSuccess,
  changeRoleFailure,
  destroyMembershipSuccess,
  destroyMembershipFailure,
} from "./actions"

export function* FETCH_ALL() {
  const { data, errors = [] } = yield call(listRecords)

  if (errors.length > 0) {
    yield put(fetchUsersFail(errors))
  } else {
    yield put(fetchUsersSuccess(data))
  }
}

export function* FETCH_SINGLE({ payload: { id } }) {
  const { data, included, errors = [] } = yield call(getRecord, id)

  if (errors.length > 0) {
    yield put(fetchUserFail(errors))
  } else {
    yield put(fetchUserSuccess({ record: data, included }))
  }
}

const manageRoleFailure = (errors) => {
  notification.error({
    message: "Request Failed",
    description: errors,
  })
  return changeRoleFailure(errors)
}

export function* CHANGE_ROLE({ payload: { id, role }}) {
  yield *makeCall(
    update,
    changeRoleSuccess,
    manageRoleFailure,
    id,
    role
  )
}

const manageDestroyMembershipSuccess = (data) =>  {
  notification.success({
    message: "Membership Removed",
    description: "The selected membership was removed.",
  })
  return destroyMembershipSuccess(data)
}

const manageDestroyMembershipFailure = (errors) => {
  notification.error({
    message: "Request Failed",
    description: errors,
  })
  return destroyMembershipFailure(errors)
}

export function* DESTROY_MEMBERSHIP({ payload: { id } }) {
  yield *makeCall(
    destroy,
    manageDestroyMembershipSuccess,
    manageDestroyMembershipFailure,
    id
  )
}

export default function* usersSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.FETCH_SINGLE, FETCH_SINGLE),
    takeEvery(actions.CHANGE_ROLE, CHANGE_ROLE),
    takeEvery(actions.DESTROY_MEMBERSHIP, DESTROY_MEMBERSHIP),
  ])
}
