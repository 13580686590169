import authenticatedRequest from "./authenticatedRequest"

// send an authenticated request to /me/email in the api
export async function updateEmail(params = {}) {
  return authenticatedRequest("/me/email", "PUT", params)
}

// send an authenticated request to /me/password in the api
export async function updatePassword(params = {}) {
  return authenticatedRequest("/me/password", "PUT", params)
}

// send an authenticated request to /me in the api
export async function updateProfile(params = {}) {
  return authenticatedRequest("/me", "PUT", params)
}
