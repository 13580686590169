import actions from "./actions"
import {
  getAllReducer,
  getAllReducerComplete,
  createReducer,
  createReducerComplete,
  deleteReducer,
  deleteReducerComplete,
  initialState,
} from "../reducers/utils"

export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL:
    case actions.FETCH_FACILITATOR_INFO:
      return getAllReducer(state, action)

    case actions.FETCH_ALL_OK:
    case actions.FETCH_ALL_FAIL:
      return getAllReducerComplete(state, action)

    case actions.CREATE:
      return createReducer(state, action)

    case actions.CREATE_SUCCESS:
    case actions.CREATE_FAIL:
      return createReducerComplete(state, action)

    case actions.DESTROY:
      return deleteReducer(state, action)

    case actions.DESTROY_SUCCESS:
    case actions.DESTROY_FAIL:
      return deleteReducerComplete(state, action)

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    default:
      return state
  }
}