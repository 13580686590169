import { all, takeEvery, put, call } from "redux-saga/effects"
import { push } from "connected-react-router"
import { notification } from "antd"
import { create, deleteRecord, listRecords, redeem, accept } from "services/invitations"
import {
  makeCall,
} from "../sagas/utils"
import actions, {
  createInvitationSuccess,
  createInvitationFail,
  deleteInvitationSuccess,
  deleteInvitationFail,
  fetchInvitationsSuccess,
  fetchInvitationsFail,
  redeemInvitationSuccess,
  redeemInvitationFail,
  acceptOrganizationInvitationSuccess,
  acceptOrganizationInvitationFail,
} from "./actions"

export function* CREATE({ payload: { record } }) {
  const { errors = [] } = yield call(create, record)
  const { organizationId } = record

  if (errors.length > 0) {
    notification.warning({
      message: "Request Failed",
      description: errors,
    })
    yield put(createInvitationFail())
    return
  }

  yield put(createInvitationSuccess())

  if (organizationId) {
    notification.success({
      message: "User Invited",
      description: `${record.email} was invited to this organization.`,
    })

    yield put(push(`/organizations/${organizationId}`))
    return
  }

  notification.success({
    message: "Admin Invitation Sent",
    description: `${record.email} has been invited as an admin.`,
  })

  yield put(push(`/admin/users`))
}

export function* DELETE({ payload: { id } }) {
  const success = yield* makeCall(
    deleteRecord,
    deleteInvitationSuccess,
    deleteInvitationFail,
    id
  )

  if (success) {
    notification.success({
      message: "Invitation Removed",
      description: "The invitation was deleted.",
    })
  }
}

const manageFailure = (errors) => {
  notification.error({
    message: "Invitation Failed",
    description: errors
  })
  return acceptOrganizationInvitationFail(errors)
}

export function* ACCEPT_ORGANIZATION_INVITATION({payload: {id}}) {
  const success = yield* makeCall(
    accept,
    acceptOrganizationInvitationSuccess,
    manageFailure,
    id
  )

  if (success) {
    notification.success({
      message: "Invitation Accepted",
      description: "You're now a member"
    })
  }
  yield put({ type: "currentUser/LOAD_CURRENT_ACCOUNT" })
}

export function* FETCH_ALL() {
  const { data, errors = [] } = yield call(listRecords)

  if (errors.length > 0) {
    yield put(fetchInvitationsFail(errors))
    return
  }

  yield put(fetchInvitationsSuccess(data))
}

export function* REDEEM({ payload }) {
  const { id, values } = payload
  const { errors } = yield call(redeem, id, values)

  if (errors.length > 0) {
    notification.warning({
      message: "Account creation error",
      description: errors,
    })
    yield put(redeemInvitationFail())
    return
  }

  notification.success({
    message: "Account created",
    description: "Your account has been created. You may now login.",
  })
  yield put(redeemInvitationSuccess())
  yield put(push(`/login`))
}

export default function* invitationsSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.REDEEM, REDEEM),
    takeEvery(actions.ACCEPT_ORGANIZATION_INVITATION, ACCEPT_ORGANIZATION_INVITATION),
  ])
}
