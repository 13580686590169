import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import currentUser from "./currentUser/reducers"
import users from "./users/reducers"
import menu from "./menu/reducers"
import settings from "./settings/reducers"
import organizations from "./organizations/reducers"
import memberships from "./memberships/reducers"
import seasons from "./seasons/reducers"
import invitations from "./invitations/reducers"
import groups from "./groups/reducers"
import members from "./members/reducers"
import sessions from "./sessions/reducers"
import facilitators from "./facilitators/reducers"

// Each state key, other than router, should have nested state shaped, at
// minimum, like this:
//
// {
//   records: [],    // list of fetched records for index views
//   record: null,   // single record fetched for show/edit view
//   id: null,       // if of current single record
//   loading: false, // whether the full page is loading server data for display
//   saving: false,  // when a button click is waiting on server-confirm
//   error: null,    // errors from a server request
// };

export default history =>
  combineReducers({
    router: connectRouter(history),
    currentUser,
    users,
    menu,
    settings,
    organizations,
    memberships,
    seasons,
    invitations,
    groups,
    members,
    sessions,
    facilitators,
  })
