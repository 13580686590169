import { all, takeEvery, put, call, select } from "redux-saga/effects"
import { notification } from "antd"
import { listGroupRecords, listSeasonRecords, createRecord, destroyRecord } from "services/member"
import { addMember, removeMember } from "../groups/actions"
import { getMembers } from "../sagas/selectors"
import { makeCall, findRecord } from "../sagas/utils"
import actions, {
  fetchMembersSuccess,
  fetchMembersFail,
  createMemberSuccess,
  createMemberFail,
  destroyMemberSuccess,
  destroyMemberFail,
} from "./actions"

export function* FETCH_ALL({ payload: { groupId, seasonId } }) {
  const id = seasonId || groupId
  const listCall = seasonId ? listSeasonRecords : listGroupRecords
  const { data, status, errors = [] } = yield call(listCall, id)

  if (errors.length > 0 || status > 399) {
    if (errors.length === 0) {
      errors.push("Your request failed. Please try again.")
    }
    yield put(fetchMembersFail(errors))
    return
  }

  yield put(fetchMembersSuccess(data))
}

export function* CREATE({ payload }) {
  const { groupId, userId } = payload

  const successResponse = yield* makeCall(createRecord, createMemberSuccess, createMemberFail, groupId, userId)

  if (successResponse) {
    const { data } = successResponse
    yield put(addMember({ id:  groupId, item: data }))
  }
}

function* getState() {
  return yield select(getMembers)
}

export function* DESTROY({ payload: { id } }) {
  const memberState = yield* getState()
  const record = findRecord(memberState, memberState.id)
  const { attributes: { groupId } } = record
  const { status, errors = [] } = yield call(destroyRecord, id)

  if (errors.length > 0 || status > 399) {
    if (errors.length === 0) {
      errors.push('Record could not be deleted.  Please try again or contact support.')
    }
    notification.warning({
      message: "Request Failed",
      description: errors,
    })
    yield put(destroyMemberFail(errors))
    return
  }

  notification.success({
    message: "Member Removed",
    description: "The member was removed from this group.",
  })
  yield put(destroyMemberSuccess())
  yield put(removeMember({ id:  groupId, item: record }))
}

export default function* membersSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL, FETCH_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DESTROY, DESTROY),
  ])
}
