export const getGroups = state => state.groups

export const getMembers = state => state.members

export const getSessions = state => state.sessions

export const getSeasons= state => state.seasons

export const getFacilitators= state => state.facilitators

export const getCurrentUser = state => state.currentUser
