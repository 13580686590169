import { createResource, getResource, listResource } from "../clients/resource"
import { createSession, activeUser, endSession } from "../clients/sessions"
import { updateProfile, updateEmail, updatePassword } from "../clients/profile"

export async function listRecords() {
  return listResource("users")
}

export async function getRecord(id) {
  return getResource("users", id)
}

export async function resetPassword(id, password, passwordConfirmation) {
  return createResource("password_reset/", {id, password, passwordConfirmation})
}

export async function resetPasswordGenerate(email) {
  return createResource("password_reset/generate", email)
}

export async function login(email, password) {
  return createSession({ email, password })
}

export async function currentAccount() {
  return activeUser()
}

export async function logout() {
  await endSession()
  localStorage.removeItem("sessionKey")
  return true
}

export function loggedIn() {
  const key = localStorage.getItem("sessionKey")
  return !!key && key !== "undefined"
}

export function sessionKey() {
  return localStorage.getItem("sessionKey")
}

export async function update(params) {
  return updateProfile({ user: params })
}

// can't call it updateEmail because that's the name of the function that sends a request to the api
export async function updateUserEmail(newEmail, password) {
  return updateEmail({ user: { newEmail, password } })
}

// can't call it updatePassword because that's the name of the function that sends a request to the api
export async function updateUserPassword(password, newPassword) {
  return updatePassword({ user: { password, newPassword } })
}

export function isAdmin({ role }) {
  return role === "admin"
}

export function isAuthorized({ user, roles, authorizedUserIds }) {
  const { role, id } = user
  return roles.includes(role) || authorizedUserIds.includes(id)
}
