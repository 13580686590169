const actions = {
  SET_STATE: "organizations/SET_STATE",
  FETCH_SINGLE: "organizations/FETCH_SINGLE",
  FETCH_SINGLE_OK: "organizations/FETCH_SINGLE_OK",
  FETCH_SINGLE_FAIL: "organizations/FETCH_SINGLE_FAIL",
  FETCH_ALL: "organizations/FETCH_ALL",
  FETCH_ALL_OK: "organizations/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "organizations/FETCH_ALL_FAIL",
  CREATE: "organizations/CREATE",
  CREATE_SUCCESS: "organizations/CREATE_SUCCESS",
  CREATE_FAIL: "organizations/CREATE_FAILURE",
  UPDATE: "organizations/UPDATE",
  DELETE: "organizations/DELETE",
  UPDATE_SUCCESS: "organizations/UPDATE_SUCCESS",
  UPDATE_FAIL: "organizations/UPDATE_FAILURE",
}

export const createOrganization = payload => ({
  type: actions.CREATE,
  payload,
})

export const createOrganizationSuccess = record => ({
  type: actions.CREATE_SUCCESS,
  payload: { record }
})

export const createOrganizationFail = error => ({
  type: actions.CREATE_FAIL,
  payload: { error },
})

export const deleteOrganization =  id => ({
  type: actions.DELETE,
  payload: { id },
})

export const updateOrganization = payload => ({
  type: actions.UPDATE,
  payload,
})

export const updateOrganizationSuccess = record => ({
  type: actions.UPDATE_SUCCESS,
  payload: { record },
})

export const updateOrganizationFail = error => ({
  type: actions.UPDATE_FAIL,
  payload: { error },
})

export const fetchOrganization = id => ({
  type: actions.FETCH_SINGLE,
  payload: { id },
})

export const fetchOrganizationSuccess = record => ({
  type: actions.FETCH_SINGLE_OK,
  payload: { record },
})

export const fetchOrganizationFail = error => ({
  type: actions.FETCH_SINGLE_FAIL,
  payload: { error },
})

export const fetchOrganizations = () => ({
  type: actions.FETCH_ALL,
})

export const fetchOrganizationsSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchOrganizationsFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const setOrganizationEditing = editing => ({
  type: actions.SET_STATE,
  payload: { editing }
})

export const startEditing = () => ({
  type: actions.SET_STATE,
  payload: { editing: true }
})

export const stopEditing = () => ({
  type: actions.SET_STATE,
  payload: { editing: false }
})

export default actions
