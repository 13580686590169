import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import actions from "redux/currentUser/actions"
import OrganizationSwitcher from './OrganizationSwitcher'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

const mapStateToProps = ({ currentUser }) => ({ currentUser })

function TopBar() {
  const { currentUser } = useSelector(mapStateToProps)
  const dispatch = useDispatch()
  const switchOrg = (orgId) => {
    dispatch({
      type: actions.UPDATE,
      payload: { currentOrganizationId: orgId },
    })
  }

  return (
    <div className={styles.topbar}>
      <div className="mr-auto" />
      {currentUser.organizations.length >= 2 &&
        <div className="mr-4">
          <OrganizationSwitcher user={currentUser} onSwitch={switchOrg} />
        </div>
      }
      <ProfileMenu />
    </div>
  )
}

export default TopBar
