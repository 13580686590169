import actions from "./actions"
import {
  deleteReducer,
  deleteReducerComplete,
  getReducer,
  getReducerComplete,
  getAllReducer,
  getAllReducerComplete,
  createReducer,
  createReducerComplete,
  updateReducer,
  updateReducerComplete,
  getResourceReducer,
  getResourceReducerComplete,
  addResourceItemReducer,
  removeResourceItemReducer,
  initialState,
} from "../reducers/utils"

/*
 * This function gets called from the entitiesReducer in `../reducers.js`
 * and delegates actions to functions within this file based on the action type
 * @param  {Object} state   the entities Redux state slice
 * @param  {Object} action  the called action
 */
export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.DELETE:
      return deleteReducer(state, action)

    case actions.DELETE_OK:
    case actions.DELETE_FAIL:
      return deleteReducerComplete(state, action)

    case actions.FETCH_SINGLE:
      return getReducer(state, action);

    case actions.FETCH_SINGLE_SUCCESS:
    case actions.FETCH_SINGLE_FAIL:
      return getReducerComplete(state, action);

    case actions.FETCH_ALL:
      return getAllReducer(state, action)

    case actions.FETCH_ALL_OK:
    case actions.FETCH_ALL_FAIL:
      return getAllReducerComplete(state, action)

    case actions.CREATE:
      return createReducer(state, action)

    case actions.CREATE_SUCCESS:
    case actions.CREATE_FAIL:
      return createReducerComplete(state, action)

    case actions.UPDATE:
      return updateReducer(state, action)

    case actions.UPDATE_SUCCESS:
    case actions.UPDATE_FAIL:
      return updateReducerComplete(state, action)

    case actions.FETCH_MEMBERS:
      return getResourceReducer(state, action, "members")

    case actions.FETCH_MEMBERS_SUCCESS:
    case actions.FETCH_MEMBERS_FAIL:
      return getResourceReducerComplete(state, action, "members")

    case actions.ADD_MEMBER:
      return addResourceItemReducer(state, action, "members")
    case actions.REMOVE_MEMBER:
      return removeResourceItemReducer(state,  action,  "members")

    case actions.FETCH_SESSIONS:
      return getResourceReducer(state, action, "sessions")

    case actions.FETCH_SESSIONS_SUCCESS:
    case actions.FETCH_SESSIONS_FAIL:
      return getResourceReducerComplete(state, action, "sessions")

    case actions.ADD_SESSION:
      return addResourceItemReducer(state, action, "sessions")
    case actions.REMOVE_SESSION:
      return removeResourceItemReducer(state,  action,  "sessions")

    default:
      return state
  }
}