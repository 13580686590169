const actions = {
  SET_STATE: "users/SET_STATE",

  FETCH_ALL: "users/FETCH_ALL",
  FETCH_ALL_OK: "users/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "users/FETCH_ALL_FAIL",

  FETCH_SINGLE: "users/FETCH_SINGLE",
  FETCH_SINGLE_OK: "users/FETCH_SINGLE_OK",
  FETCH_SINGLE_FAIL: "users/FETCH_SINGLE_FAIL",


  CHANGE_ROLE: "users/CHANGE_ROLE",
  CHANGE_ROLE_SUCCESS: "users/CHANGE_ROLE_SUCCESS",
  CHANGE_ROLE_FAILURE: "users/CHANGE_ROLE_FAILURE",
  DESTROY_MEMBERSHIP: "users/DESTROY_MEMBERSHIP",
  DESTROY_MEMBERSHIP_SUCCESS: "users/DESTROY_MEMBERSHIP_SUCCESS",
  DESTROY_MEMBERSHIP_FAILURE: "users/DESTROY_MEMBERSHIP_FAILURE",
}

export const setState = attrs => ({
  type: actions.SET_STATE,
  payload: attrs,
})

export const setUsers = records => setState({ records })

export const fetchUsers = () => ({
  type: actions.FETCH_ALL,
})

export const fetchUsersSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchUsersFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const fetchUser = id => ({
  type: actions.FETCH_SINGLE,
  payload: { id },
})

export const fetchUserSuccess = (payload) => ({
  type: actions.FETCH_SINGLE_OK,
  payload,
})

export const fetchUserFail = error => ({
  type: actions.FETCH_SINGLE_FAIL,
  payload: { error },
})

export const changeRole = (id, role, organizationId) => ({
  type: actions.CHANGE_ROLE,
  payload: { id, role, organizationId, saving: id },
})

export const changeRoleSuccess = record => ({
  type: actions.CHANGE_ROLE_SUCCESS,
  payload: { record },
})

export const changeRoleFailure = error => ({
  type: actions.CHANGE_ROLE_FAILURE,
  payload: { error },
})

export const destroyMembership = id => ({
  type: actions.DESTROY_MEMBERSHIP,
  payload: { id } ,
})

export const destroyMembershipSuccess = payload => ({
  type: actions.DESTROY_MEMBERSHIP_SUCCESS,
  payload,
})

export const destroyMembershipFailure = error => ({
  type: actions.DESTROY_MEMBERSHIP_FAILURE,
  payload: { error },
})

export default actions
