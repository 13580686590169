import moment from "moment"
import { groupRoute } from "./group"
import { sessionRoute } from "./session"
import { routes } from "../router"

// Standard helper for formatting date times across the system. Pass in an ISO
// 8601 date string and moment will handle all the hard work of converting to
// date, localizing to current TZ, and formatting. Current supported formats:
//
// - default - "3/20/2020 5:49pm"
export function formatDate(dateStr, type="default") {
  switch (type) {
    case "default":
    default:
      return moment(dateStr).format("M/DD/YYYY h:mma")
  }
}

/*
 * This function returns the nextDate (compared to today) or null, if no such dates
 *
 * @param  [Array] dates   an array of dates
 */
export function nextDate(dates) {
  const now = moment()
  const nextEvents = dates.filter(Boolean)
                          .map(d => moment(d))
                          .filter(date => date > now)
  if (nextEvents.length === 0) { return null }

  return Math.min(...nextEvents)
}

/*
 * This function returns the mostRecentDate (compared to today) or null, if no such dates
 *
 * @param  [Array] dates   an array of dates
 */
export function mostRecentDate(dates) {
  const now = moment()
  const pastEvents = dates.filter(Boolean)
                          .map(d => moment(d))
                          .filter(date => date <= now)
  if (pastEvents.length === 0) { return null }

  return Math.max(...pastEvents)
}

/*
 * This function returns the objects sorted in date order (with no dates at the end)
 *
 * @param  {Array of objects} objects    an array of dates
 * @param  {string} dateKey              key of object that is the date field we should use to compare
 */
export function sortedByDate(objects, dateKey) {
  return objects.sort((a, b) => {
    const aDate = a[dateKey]
    const bDate = b[dateKey]

    return (aDate === null) - (bDate === null) ||
     + (Date.parse(aDate) > Date.parse(bDate))||
     - (Date.parse(aDate) < Date.parse(bDate))
  })
}


export function normalRoutes(routeName, object) {
  const route = routes.find(r => r.name === routeName)

  if (!route) { return null }

  const { path } = route
  const pathSegments = path.split("/")
  const routeSegments = pathSegments.map(segment => {
    if (segment.startsWith(":")) {
      return object.id
    }

    return segment
  })

  return `${routeSegments.join("/")}`
}

// Use for links
export function linkTo(routeName, object) {
  return `#${pathFor(routeName, object)}`
}

// Use for history routes
export function pathFor(routeName, object) {
  const route = routes.find(r => r.name === routeName)

  if (!route) { return null }

  if (routeName.startsWith("groups")) {
    return groupRoute(routeName, object)
  }

  if (routeName.startsWith("sessions")) {
    return sessionRoute(routeName, object)
  }

  // works for non-nested routes, where the object passed in has an 'id'
  return normalRoutes(routeName, object)
}
