import antdData from 'antd/lib/locale-provider/fr_FR'
import localeData from 'react-intl/locale-data/fr'

const messages = {
  'topBar.typeToSearch': 'Chercher...',
  'topBar.profileMenu.hello': 'Bonjour',
  'topBar.profileMenu.billingPlan': 'Plan de facturation',
  'topBar.profileMenu.role': 'Rôle',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Téléphone',
  'topBar.profileMenu.editProfile': 'Editer le profil',
  'topBar.profileMenu.logout': 'Connectez - Out',
}

export default {
  locale: 'fr-FR',
  antdData,
  localeData,
  messages,
}
