const actions = {
  SET_STATE: "members/SET_STATE",

  FETCH_ALL: "members/FETCH_ALL",
  FETCH_ALL_OK: "members/FETCH_ALL_OK",
  FETCH_ALL_FAIL: "members/FETCH_ALL_FAIL",

  CREATE: "members/CREATE",
  CREATE_SUCCESS: "members/CREATE_SUCCESS",
  CREATE_FAIL: "members/CREATE_FAIL",

  DESTROY: "members/DESTROY",
  DESTROY_SUCCESS: "members/DESTROY_SUCCESS",
  DESTROY_FAIL: "members/DESTROY_FAIL",
}

export const setState = attrs => ({
  type: actions.SET_STATE,
  payload: attrs,
})

export const fetchMembers = groupId => ({
  type: actions.FETCH_ALL,
  payload: { groupId },
})

export const fetchMembersSuccess = records => ({
  type: actions.FETCH_ALL_OK,
  payload: { records },
})

export const fetchMembersFail = error => ({
  type: actions.FETCH_ALL_FAIL,
  payload: { error },
})

export const createMember = payload => ({
  type: actions.CREATE,
  payload,
})

export const createMemberSuccess = record => ({
  type: actions.CREATE_SUCCESS,
  payload: { record },
})

export const createMemberFail = error => ({
  type: actions.CREATE_FAIL,
  payload: { error },
})

export const destroyMember = id => ({
  type: actions.DESTROY,
  payload: { id },
})

export const destroyMemberSuccess = payload => ({
  type: actions.DESTROY_SUCCESS,
  payload: { payload }
})

export const destroyMemberFail = error => ({
  type: actions.DESTROY_FAIL,
  payload: { error }
})

export default actions
